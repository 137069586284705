import { Button, Col, Divider, Form, FormInstance, Input, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { GatePassItemsTable } from '../../../components/GatePassItemsTable/GatePassItemsTable';
import { GatePassTimeline } from '../../../components/GatePassTimeline/GatePassTimeline';
import './ReturnGatePassForm.scss';

interface ReturnGatePassFormProps {
    gatePassNo: string;
    gatePassData: any;
    onFormClose: () => void;
}

interface ReturnGatePassFormState { }

export class ReturnGatePassForm extends React.Component<ReturnGatePassFormProps, ReturnGatePassFormState> {
    private formRef: React.RefObject<FormInstance>;

    constructor(props: ReturnGatePassFormProps) {
        super(props);

        this.state = {};

        this.formRef = React.createRef();
    }

    private onFormClose = () => {
        this.formRef.current?.resetFields();
        this.props.onFormClose();
    };

    componentDidMount(): void {
        this.formRef.current?.setFieldsValue(this.props.gatePassData);
    }

    componentDidUpdate(prevProps: Readonly<ReturnGatePassFormProps>): void {
        if (this.props.gatePassData !== prevProps.gatePassData) {
            this.formRef.current?.setFieldsValue(this.props.gatePassData);
        }
    }

    render(): React.ReactNode {
        const { gatePassNo, gatePassData } = this.props;

        return (
            <Form
                ref={this.formRef}
                layout="vertical"
                className='return-gate-pass-form'
            >
                <Row gutter={16}>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item name="type" label="Gate Pass Type">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item name="checkOutDate" label="Check Out Date">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item name="returnDate" label="Return Date">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item name="vehicleNo" label="Vehicle No">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="location" label="Location">
                    <Input disabled />
                </Form.Item>

                <Form.Item name="reason" label="Reason">
                    <TextArea rows={2} disabled />
                </Form.Item>

                <Form.Item name="holderName" label="Gate Pass Holder">
                    <Input disabled />
                </Form.Item>

                <Form.Item name="approverName" label="Gate Pass Approver">
                    <Input disabled />
                </Form.Item>

                <Divider></Divider>

                <GatePassItemsTable gatePassNo={gatePassNo} view={"PENDING_RETURNS"} gatePassType={gatePassData.type} />

                <Divider></Divider>

                <GatePassTimeline gatePassNo={gatePassNo} />

                <Form.Item className="action-container">
                    <Button type="default" className="cancel-btn" onClick={this.onFormClose}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}