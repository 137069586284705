import React from "react";
import "./BasePage.scss";



export interface BasePageConfig {
    title: string;
    pageClassName?: string;
}

export interface BasePageProps { }

export interface BasePageState { }


export abstract class BasePage<P extends BasePageProps, S extends BasePageState> extends React.Component<P, S> {

    protected abstract pageConfig: BasePageConfig;

    constructor(props: P) {
        super(props);

        this.state = {} as S;
    }

    protected globalActionsRenderer = (): JSX.Element => {
        return (
            <div></div>
        )
    }

    protected pageBodyRenderer = (): JSX.Element => {
        return (
            <div></div>
        )
    }


    // protected getPageConfig = (): BasePageConfig => {
    //     return {};
    // }

    render(): React.ReactNode {
        const { title, pageClassName } = this.pageConfig;
        return (
            <div className={`base-page ${pageClassName ?? ""}`}>
                <div className="page-header">
                    <div className="title">{title}</div>

                    <div className="global-actions">
                        {this.globalActionsRenderer()}
                    </div>
                </div>

                {/*<div className="divider"></div>*/}

                <div className="page-body">
                    {this.pageBodyRenderer()}
                </div>

                {/*<div className="divider"></div>*/}

                {/*<div className="page-footer"></div>*/}
            </div>
        )
    }
}