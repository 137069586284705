import {
    CheckCircleOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    LockOutlined,
    UnlockOutlined,
    UsergroupAddOutlined,
    EditOutlined,
    ClockCircleOutlined
} from "@ant-design/icons";
import { Button, Input, Table, TableColumnsType, Tag, Tooltip } from "antd";
import React from "react";
import { GenericConfirmationModal } from "../../../components/GenericConfirmationModal/GenericConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import { UserRole } from "../../../defs/common";
import { UserManagerService } from "../../../services/admin/UserManagerService";
import { getUserRoleDisplayValue } from "../../../utils/utils";
import { GenericModal } from "../../../components/GenericModal/GenericModal";
import { UserEditForm } from "../../../components/UserEditForm/UserEditForm";
import './UsersTable.scss';

const tableHeight = window.innerHeight - 200;
const HoursToAdd = 24;

const TextFilter: React.FC<{ setSelectedKeys: any; selectedKeys: any; confirm: () => void; }> = ({ setSelectedKeys, selectedKeys, confirm }) => (
    <Input
        placeholder="Search"
        value={selectedKeys[0] || ''}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={confirm}
        onBlur={confirm}
    />
);

export interface UsersTableData {
    key: string;
    firstName: string;
    lastName: string;
    email: string;
    role: UserRole;
    isActive: boolean;
}

interface UsersTableProps {
    rows: UsersTableData[];
    isLoading: boolean;
    onActionComplete?: () => void;
}

interface UsersTableState {
    selectedRow?: any;
    isDeleteConfirmationModalVisible: boolean;
    isStatusChangeConfirmationModalVisible: boolean;
    isRoleUpgradeConfirmationModalVisible: boolean;
    isEditUserModalVisible: boolean;
    isAllowGPCreationModalVisible: boolean;
    isInProgress: boolean;
}

export class UsersTable extends React.Component<UsersTableProps, UsersTableState> {
    constructor(props: UsersTableProps) {
        super(props);

        this.state = {
            isDeleteConfirmationModalVisible: false,
            isStatusChangeConfirmationModalVisible: false,
            isRoleUpgradeConfirmationModalVisible: false,
            isEditUserModalVisible: false,
            isAllowGPCreationModalVisible: false,
            selectedRow: undefined,
            isInProgress: false
        };
    }

    private userStatusIndicatorChip = (cellValue: any, rowData: any): JSX.Element => {
        return (
            <Tag className="user-status-indicator-chip"
                 icon={ this.getUserStatusChipIcon(rowData) }
                 color={ this.getUserStatusChipColor(rowData) }>
                { this.getUserStatusChipText(rowData) }
            </Tag>
        );
    };

    private getUserStatusChipIcon = (rowData: any) => {
        const isActive: boolean = rowData?.isActive;
        return isActive ? <CheckCircleOutlined/> : <ExclamationCircleOutlined/>;
    };

    private getUserStatusChipColor = (rowData: any) => {
        const isActive: boolean = rowData?.isActive;
        return isActive ? "success" : "error";
    };

    private getUserStatusChipText = (rowData: any) => {
        const isActive: boolean = rowData?.isActive;
        return isActive ? "Active" : "Inactive";
    };

    private getUserActionStatusCellRenderer = (cellValue: any, rowData: any): JSX.Element => {
        return (
            <Tag className="action-status-indicator-chip"
                 icon={ this.getActionStatusChipIcon(rowData) }
                 color={ this.getActionStatusChipColor(rowData) }>
                { this.getActionStatusChipText(rowData) }
            </Tag>
        );
    };

    private getActionStatusChipIcon = (rowData: any) => {
        const isGatePassCreationNotBanned: boolean = rowData?.isGatePassCreationNotBanned;
        const hasGPCreationBanLiftedFor24Hrs: boolean = rowData?.hasGPCreationBanLiftedFor24Hrs;
        return isGatePassCreationNotBanned ? (hasGPCreationBanLiftedFor24Hrs ? <ExclamationCircleOutlined/> :
            <CheckCircleOutlined/>) : <ExclamationCircleOutlined/>;
    };

    private getActionStatusChipColor = (rowData: any) => {
        const isGatePassCreationNotBanned: boolean = rowData?.isGatePassCreationNotBanned;
        const hasGPCreationBanLiftedFor24Hrs: boolean = rowData?.hasGPCreationBanLiftedFor24Hrs;
        return isGatePassCreationNotBanned ? (hasGPCreationBanLiftedFor24Hrs ? "warning" : "success") : "error";
    };

    private getActionStatusChipText = (rowData: any) => {
        const isGatePassCreationNotBanned: boolean = rowData?.isGatePassCreationNotBanned;
        const hasGPCreationBanLiftedFor24Hrs: boolean = rowData?.hasGPCreationBanLiftedFor24Hrs;
        return isGatePassCreationNotBanned ? (hasGPCreationBanLiftedFor24Hrs ? "GP Creation - Ban Lifted (24-Hr)" : "GP Creation - Allowed") : "GP Creation - Banned";
    };

    private showDeleteConfirmationModal = (selectedRow: any): void => {
        this.setState({ selectedRow, isDeleteConfirmationModalVisible: true });
    };

    private showStatusChangeConfirmationModal = (selectedRow: any): void => {
        this.setState({ selectedRow, isStatusChangeConfirmationModalVisible: true });
    };

    private showRoleUpgradeConfirmationModal = (selectedRow: any): void => {
        this.setState({ selectedRow, isRoleUpgradeConfirmationModalVisible: true });
    };

    private showEditUserModal = (selectedRow: any): void => {
        this.setState({ selectedRow, isEditUserModalVisible: true });
    };

    private showAllowGPCreationModal = (selectedRow: any): void => {
        this.setState({ selectedRow, isAllowGPCreationModalVisible: true });
    };

    private closeDeleteConfirmationModal = (): void => {
        this.setState({ selectedRow: undefined, isDeleteConfirmationModalVisible: false });
    };

    private closeStatusChangeConfirmationModal = (): void => {
        this.setState({ selectedRow: undefined, isStatusChangeConfirmationModalVisible: false });
    };

    private closeRoleUpgradeConfirmationModal = (): void => {
        this.setState({ selectedRow: undefined, isRoleUpgradeConfirmationModalVisible: false });
    };

    private closeEditUserModal = (): void => {
        this.setState({ selectedRow: undefined, isEditUserModalVisible: false });
    };

    private closeAllowGPCreationModal = (): void => {
        this.setState({ selectedRow: undefined, isAllowGPCreationModalVisible: false });
    };

    private deleteUser = async (): Promise<void> => {

        const { selectedRow } = this.state;
        const { onActionComplete } = this.props;

        if (!selectedRow) {
            Toast.error("Delete User", "User deletion is failed!");
            return;
        }

        this.setState({ isInProgress: true });

        try {
            await UserManagerService.getInstance().deleteUserByUserId(selectedRow.userId);
            onActionComplete?.();

            Toast.success("Delete User", "User deleted successfully!");
        } catch (error: any) {
            Toast.error("Delete User", error.message ?? "User deletion failed!");
        } finally {
            this.setState({ isInProgress: false });
            this.closeDeleteConfirmationModal();
        }
    };

    private changeActivationStatus = async (): Promise<void> => {

        const { selectedRow } = this.state;
        const { onActionComplete } = this.props;

        if (!selectedRow) {
            Toast.error("User Activation/Deactivation", "User activation/deactivation is failed!");
            return;
        }

        this.setState({ isInProgress: true });
        const activationStatus: "ACTIVATE" | "DEACTIVATE" = selectedRow.isActive ? "DEACTIVATE" : "ACTIVATE";

        try {
            await UserManagerService.getInstance().changeUserActivationStatus(selectedRow.userId, activationStatus);
            onActionComplete?.();

            Toast.success("User Activation/Deactivation", `User ${selectedRow.isActive ? "deactivated" : "activated"} successfully!`);
        } catch (error: any) {
            Toast.error("User Activation/Deactivation", error.message ?? "User activation/deactivation is failed!");
        } finally {
            this.setState({ isInProgress: false });
            this.closeStatusChangeConfirmationModal();
        }
    };

    private upgradeUserRole = async (): Promise<void> => {
        const { selectedRow } = this.state;
        const { onActionComplete } = this.props;

        if (!selectedRow) {
            Toast.error("User Role Upgrade", "User role upgrade is failed!");
            return;
        }

        this.setState({ isInProgress: true });

        try {
            await UserManagerService.getInstance().upgradeUserRole(selectedRow.userId, UserRole.USER_LEVEL_0);
            onActionComplete?.();

            Toast.success("User Role Upgrade", `User role upgraded successfully!`);
        } catch (error: any) {
            Toast.error("User Role Upgrade", error.message ?? "User role upgrade is failed!");
        } finally {
            this.setState({ isInProgress: false });
            this.closeRoleUpgradeConfirmationModal();
        }

    };

    private allowGPCreationFor24Hrs = async (): Promise<void> => {
        const { selectedRow } = this.state;
        const { onActionComplete } = this.props;

        if (!selectedRow) {
            Toast.error("Allow Gate Pass Creation for 24 Hrs", "User config update failed!");
            return;
        }

        this.setState({ isInProgress: true });

        UserManagerService.getInstance()
            .updateUserData(selectedRow.userId, {
                userConfig: {
                    maxGatePassesInPendingReturn: selectedRow?.maxGatePassesInPendingReturn ?? 0,
                    maxGatePassesInPendingCheckout: selectedRow?.maxGatePassesInPendingCheckout ?? 0,
                    gatePassCreationExpiresAt: this.getExpirationTimestamp(),
                }
            })
            .then(() => {
                console.info("User config updated successfully");
                Toast.success("Allow Gate Pass Creation for 24 Hrs", "User config updated successfully");
                onActionComplete?.();
                this.closeAllowGPCreationModal();
                this.setState({ isInProgress: false });
            })
            .catch((error: any) => {
                console.error("User config update failed", error ?? "");
                Toast.error("Allow Gate Pass Creation for 24 Hrs", "User config update failed");
                this.setState({ isInProgress: false });
            });

    };

    private getExpirationTimestamp = () => {
        const currentTimestamp = Date.now();
        return currentTimestamp + HoursToAdd * 60 * 60 * 1000;
    };

    private getStatusChangeButtonToolTipTitle = (rowData: any) => {
        const isGuestUser = rowData.role === UserRole.GUEST_USER;
        if (isGuestUser) {
            return "Guest users cannot be activated";
        }
        return rowData.isActive ? "Deactivate" : "Activate";
    };

    private getRowActions = (cellValue: any, rowData: any): JSX.Element => {
        const isStatusChangeDisabled = rowData.role === UserRole.GUEST_USER;
        const isRoleUpgradeDisabled = rowData.role !== UserRole.USER_LEVEL_1;
        const isUserConfigEditEnabledForUserRole = [UserRole.ADMIN, UserRole.USER_LEVEL_0, UserRole.USER_LEVEL_1].includes(rowData.role);
        const isBanLiftedWithin24Hrs = rowData?.hasGPCreationBanLiftedFor24Hrs;
        const isGatePassCreationNotBanned = rowData?.isGatePassCreationNotBanned;

        return (
            <div className="row-actions">
                <Tooltip title={this.getStatusChangeButtonToolTipTitle(rowData)}>
                    <Button
                        className={isStatusChangeDisabled ? "" : "btn-change-status"}
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={rowData.isActive ? <LockOutlined /> : <UnlockOutlined />}
                        disabled={isStatusChangeDisabled}
                        onClick={() => this.showStatusChangeConfirmationModal(rowData)}
                    />
                </Tooltip>

                <Tooltip title="Delete">
                    <Button
                        className="btn-delete-user"
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={<DeleteOutlined />}
                        onClick={() => this.showDeleteConfirmationModal(rowData)}
                    />
                </Tooltip>

                {!isRoleUpgradeDisabled && <Tooltip title={isRoleUpgradeDisabled ? "Role upgrade not available" : "Role upgrade"}>
                    <Button
                        className={isRoleUpgradeDisabled ? "" : "btn-upgrade-user"}
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={<UsergroupAddOutlined />}
                        disabled={isRoleUpgradeDisabled}
                        onClick={() => this.showRoleUpgradeConfirmationModal(rowData)}
                    />
                </Tooltip>}

                { isUserConfigEditEnabledForUserRole && <Tooltip title={ "Edit User Configs" }>
                    <Button
                        className={ "btn-change-edit-user" }
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={ <EditOutlined/> }
                        onClick={ () => this.showEditUserModal(rowData) }
                    />
                </Tooltip> }

                { isUserConfigEditEnabledForUserRole && !isGatePassCreationNotBanned &&
                    <Tooltip title={ "Allow 24-Hr GP Creation" }>
                    <Button
                        className={ "btn-change-allow-gp-creation" }
                        type="primary"
                        shape="circle"
                        size="small"
                        icon={ <ClockCircleOutlined/> }
                        disabled={ isGatePassCreationNotBanned && isBanLiftedWithin24Hrs }
                        onClick={ () => this.showAllowGPCreationModal(rowData) }
                    />
                </Tooltip> }
            </div>
        );
    };

    render(): React.ReactNode {
        const { rows, isLoading } = this.props;
        const {
            isDeleteConfirmationModalVisible,
            selectedRow,
            isInProgress,
            isStatusChangeConfirmationModalVisible,
            isRoleUpgradeConfirmationModalVisible,
            isEditUserModalVisible,
            isAllowGPCreationModalVisible
        } = this.state;
        const deleteConfirmationMessage: string = `Do you want to DELETE the user: ${selectedRow?.firstName} ${selectedRow?.lastName}?`;
        const changeStatusConfirmationMessage: string = `Do you want to ${selectedRow?.isActive ? "Deactivate" : "Activate"} the user: ${selectedRow?.firstName} ${selectedRow?.lastName}?`;
        const roleUpgradeConfirmationMessage: string = `Do you want to upgrade ${selectedRow?.firstName} ${selectedRow?.lastName}'s user role to Approver?`;
        const allowGPCreationConfirmationMessage: string = `Do you want to allow ${ selectedRow?.firstName } ${ selectedRow?.lastName } to create Gate Passes for 24 Hrs?`;

        const columns: TableColumnsType<UsersTableData> = [
            {
                title: 'First Name',
                dataIndex: 'firstName',
                key: 'firstName',
                width: 150,
                sorter: (a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => row.firstName))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => record.firstName.includes(value as string),
                filterSearch: true
                // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                //     <TextFilter
                //         setSelectedKeys={setSelectedKeys}
                //         selectedKeys={selectedKeys}
                //         confirm={confirm}
                //     />
                // ),
                // onFilter: (value, record) => (record.firstName || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Last Name',
                dataIndex: 'lastName',
                key: 'lastName',
                width: 150,
                sorter: (a, b) => a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => row.lastName))].map(name => ({ text: name, value: name })),
                onFilter: (value, record) => record.lastName.includes(value as string),
                filterSearch: true
                // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                //     <TextFilter
                //         setSelectedKeys={setSelectedKeys}
                //         selectedKeys={selectedKeys}
                //         confirm={confirm}
                //     />
                // ),
                // onFilter: (value, record) => (record.lastName || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                width: 180,
                sorter: (a, b) => a.email?.localeCompare(b.email),
                sortDirections: ['descend', 'ascend'],
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                    <TextFilter
                        setSelectedKeys={setSelectedKeys}
                        selectedKeys={selectedKeys}
                        confirm={confirm}
                    />
                ),
                onFilter: (value, record) => (record.email || '').toLowerCase().includes((value as string).toLowerCase())
            },
            {
                title: 'Role',
                dataIndex: 'role',
                key: 'role',
                width: 180,
                render: (value) => { return getUserRoleDisplayValue(value); },
                sorter: (a, b) => getUserRoleDisplayValue(a.role).localeCompare(getUserRoleDisplayValue(b.role)),
                sortDirections: ['descend', 'ascend'],
                filters: [...new Set(rows.map(row => getUserRoleDisplayValue(row.role)))].map(type => ({ text: type, value: type })),
                onFilter: (value, record) => getUserRoleDisplayValue(record.role) === (value as string),
                filterSearch: true
            },
            {
                title: 'No of Max Pending Return',
                dataIndex: 'maxGatePassesInPendingReturn',
                key: 'maxGatePassesInPendingReturn',
                width: 130
            },
            {
                title: 'No of Max Pending Checkout',
                dataIndex: 'maxGatePassesInPendingCheckout',
                key: 'maxGatePassesInPendingCheckout',
                width: 130
            },
            {
                title: 'Status',
                dataIndex: 'isActive',
                key: 'isActive',
                width: 120,
                render: this.userStatusIndicatorChip,
                filters: [...new Set(rows.map(row => this.getUserStatusChipText(row)))].map(type => ({
                    text: type,
                    value: type
                })),
                onFilter: (value, record) => this.getUserStatusChipText(record) === (value as string),
                filterSearch: true
            },
            {
                title: 'Action Status',
                dataIndex: 'actionStatus',
                key: 'actionStatus',
                width: 120,
                render: this.getUserActionStatusCellRenderer,
                filters: [...new Set(rows.map(row => this.getActionStatusChipText(row)))].map(type => ({
                    text: type,
                    value: type
                })),
                onFilter: (value, record) => this.getActionStatusChipText(record) === (value as string),
                filterSearch: true
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                width: 120,
                fixed: 'right',
                render: this.getRowActions,
            },
        ];

        return (
            <React.Fragment>
                <Table
                    className="user-table"
                    columns={columns}
                    dataSource={rows}
                    loading={isLoading}
                    pagination={false} scroll={{ x: 'max-content', y: tableHeight }}
                    bordered
                />

                <GenericConfirmationModal
                    show={isDeleteConfirmationModalVisible}
                    title={"Delete Confirmation"}
                    description={deleteConfirmationMessage}
                    type="warning"
                    onCancel={this.closeDeleteConfirmationModal}
                    isLoading={isInProgress}
                    onConfirm={this.deleteUser}
                />

                <GenericConfirmationModal
                    show={isStatusChangeConfirmationModalVisible}
                    title={"Activate/Deactivate Confirmation"}
                    description={changeStatusConfirmationMessage}
                    type="warning"
                    onCancel={this.closeStatusChangeConfirmationModal}
                    isLoading={isInProgress}
                    onConfirm={this.changeActivationStatus}
                />

                <GenericConfirmationModal
                    show={isRoleUpgradeConfirmationModalVisible}
                    title={"User Role Upgrade"}
                    description={roleUpgradeConfirmationMessage}
                    type="info"
                    onCancel={this.closeRoleUpgradeConfirmationModal}
                    isLoading={isInProgress}
                    onConfirm={this.upgradeUserRole}
                />

                <GenericModal title="Edit User Data" open={ isEditUserModalVisible }
                              onCancel={ this.closeEditUserModal } width={ 450 }>
                    <UserEditForm currentUser={ selectedRow } onClose={ this.closeEditUserModal }/>
                </GenericModal>

                <GenericConfirmationModal
                    show={ isAllowGPCreationModalVisible }
                    title={ "Allow Gate Pass Creation for 24 Hrs" }
                    description={ allowGPCreationConfirmationMessage }
                    type="info"
                    onCancel={ this.closeAllowGPCreationModal }
                    isLoading={ isInProgress }
                    onConfirm={ this.allowGPCreationFor24Hrs }
                />
            </React.Fragment>
        );
    }
}