import { CheckOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, FormInstance, Input, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { GatePassItemsTable } from '../../../components/GatePassItemsTable/GatePassItemsTable';
import { GatePassTimeline } from '../../../components/GatePassTimeline/GatePassTimeline';
import { UserRole } from "../../../defs/common";
import { AuthenticationService, AuthUser } from "../../../services/common/AuthenticationService";
import { GatePassAction, GatePassManagerService, GatePassStatus } from "../../../services/common/GatePassManagerService";
import { Toast } from "../../Toast/Toast";
import './CheckoutGatePassForm.scss';

interface CheckoutGatePassFormProps {
    gatePassNo: string;
    gatePassData: any;
    onFormClose: () => void;
}

interface CheckoutGatePassFormState {
    checkOutInProgress: boolean;
    rejectInProgress: boolean;
    returnInProgress: boolean;
    currentUser: AuthUser | null;
}

export class CheckoutGatePassForm extends React.Component<CheckoutGatePassFormProps, CheckoutGatePassFormState> {
    private formRef: React.RefObject<FormInstance>;

    constructor(props: CheckoutGatePassFormProps) {
        super(props);

        this.state = {
            checkOutInProgress: false,
            rejectInProgress: false,
            returnInProgress: false,
            currentUser: null
        };

        this.formRef = React.createRef();
    }

    componentDidMount(): void {
        this.setCurrentUser();
        this.formRef.current?.setFieldsValue(this.props.gatePassData);
    }

    componentDidUpdate(prevProps: Readonly<CheckoutGatePassFormProps>): void {
        if (this.props.gatePassData !== prevProps.gatePassData) {
            this.formRef.current?.setFieldsValue(this.props.gatePassData);
        }
    }

    private setCurrentUser = () => {
        this.setState({ currentUser: AuthenticationService.getInstance().getCurrentUser() });
    };

    private checkoutGatePass = () => {
        this.setState({ checkOutInProgress: true });
        GatePassManagerService.getInstance().commitGatePassAction(this.props.gatePassNo, GatePassAction.CHECKOUT).then(() => {
            Toast.success("Gate pass checked out successfully");
        }).catch((error: any) => {
            console.error("Gate pass checking out failed", error ?? "");
            Toast.error("Gate pass checking out failed");
        }).finally(() => {
            this.formRef.current?.resetFields();
            this.setState({ checkOutInProgress: false });
            this.props.onFormClose();
        });
    };

    private returnGatePass = () => {
        this.setState({ returnInProgress: true });
        GatePassManagerService.getInstance().commitGatePassAction(this.props.gatePassNo, GatePassAction.REQUEST_CHANGES_BY_SECURITY_OFFICER).then(() => {
            Toast.success("Gate pass returned successfully");
        }).catch((error: any) => {
            console.error("Gate pass return failed", error ?? "");
            Toast.error("Gate pass return failed");
        }).finally(() => {
            this.formRef.current?.resetFields();
            this.setState({ returnInProgress: false });
            this.props.onFormClose();
        });
    };

    private onFormClose = () => {
        this.formRef.current?.resetFields();
        this.props.onFormClose();
    };

    render(): React.ReactNode {
        const { checkOutInProgress, returnInProgress, currentUser } = this.state;
        const { gatePassNo, gatePassData } = this.props;

        const isAllowed: boolean = currentUser !== null && [UserRole.ADMIN, UserRole.SECURITY_OFFICER].includes(currentUser?.role);
        const isActionable: boolean = gatePassData && gatePassData.status === GatePassStatus.PENDING_CHECKOUT;

        return (
            <Form
                ref={this.formRef}
                layout="vertical"
                className='checkout-gate-pass-form'
            >
                <Row gutter={16}>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item name="type" label="Gate Pass Type">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item name="checkOutDate" label="Check Out Date">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item name="returnDate" label="Return Date">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item name="vehicleNo" label="Vehicle No">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="location" label="Location">
                    <Input disabled />
                </Form.Item>

                <Form.Item name="reason" label="Reason">
                    <TextArea rows={2} disabled />
                </Form.Item>

                <Form.Item name="holderName" label="Gate Pass Holder">
                    <Input disabled />
                </Form.Item>

                <Form.Item name="approverName" label="Gate Pass Approver">
                    <Input disabled />
                </Form.Item>

                <Divider></Divider>

                <GatePassItemsTable gatePassNo={gatePassNo} gatePassType={gatePassData.type} />

                <Divider></Divider>

                <GatePassTimeline gatePassNo={gatePassNo} />

                <Form.Item className="action-container">
                    {isAllowed && isActionable &&
                        <Button icon={<CheckOutlined />} type="primary" className="approve-btn"
                            loading={checkOutInProgress}
                            disabled={returnInProgress}
                            onClick={this.checkoutGatePass}>
                            Checkout
                        </Button>}

                    {isAllowed && isActionable &&
                        <Button icon={<MinusOutlined />} type="primary" className="return-btn"
                            loading={returnInProgress}
                            disabled={checkOutInProgress}
                            onClick={this.returnGatePass}>
                            Return
                        </Button>}

                    <Button type="default" className="cancel-btn" onClick={this.onFormClose}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}