import {Divider} from "antd";
import React from "react";
import {Subscription} from "rxjs";
import {UserRole} from '../../defs/common';
import {AuthenticationService, AuthUser} from "../../services/common/AuthenticationService";
import {GatePassManagerService, GatePassStatus} from "../../services/common/GatePassManagerService";
import {isDateOlderThanToday} from '../../utils/utils';
import {StatCard, StatCardType} from "./components/StatCard";
import "./DashboardPage.scss";

interface StatItem {
	label: string;
	value: number;
	type: StatCardType;
}

interface DashboardPageProps {
}

interface DashboardPageState {
	gatePasses: any[];
	myActiveGatePassStatItems: StatItem[];
	approverActiveGatePassStatItems: StatItem[];
	gatePassDataLoading: boolean;
	currentUser: AuthUser | null;
}

export class DashboardPage extends React.Component<DashboardPageProps, DashboardPageState> {
	private gatePassDataSubscription?: Subscription;
	private isInitialized: boolean;

	constructor(props: DashboardPageProps) {
		super(props);

		this.isInitialized = false;
		this.state = {
			gatePasses: [],
			myActiveGatePassStatItems: [],
			approverActiveGatePassStatItems: [],
			gatePassDataLoading: false,
			currentUser: null
		};
	}

	private initGatePassDataSubscription = (): void => {
		this.setState({gatePassDataLoading: true});

		this.gatePassDataSubscription = GatePassManagerService.getInstance().getGatePassDataObservable().subscribe(gatePassData => {
			if (!this.isInitialized) {
				this.setState({gatePassDataLoading: false});
			}

			this.setActiveGatePassStatData(gatePassData);
			this.isInitialized = true;

		});
	};

	async componentDidMount(): Promise<void> {
		await this.setCurrentUser();
		this.initGatePassDataSubscription();
	}

	componentWillUnmount(): void {
		this.gatePassDataSubscription?.unsubscribe();
	}

	private setCurrentUser = () => {
		this.setState({currentUser: AuthenticationService.getInstance().getCurrentUser()});
	};

	private setActiveGatePassStatData = (gatePasses: any[]) => {
		const {currentUser} = this.state;

		let myTotalOpenGatePasses = 0;
		let myTotalPendingApprovals = 0;
		let myTotalPendingCheckouts = 0;
		let myTotalChangeRequests = 0;
		let myTotalPendingReturns = 0;
		let myTotalPendingReturnOverdue = 0;

		let approverTotalOpenGatePasses = 0;
		let approverTotalPendingApprovals = 0;
		let approverTotalPendingCheckouts = 0;
		let approverTotalChangeRequests = 0;
		let approverTotalPendingReturns = 0;
		let approverTotalPendingReturnOverdue = 0;

		for (const gatePass of gatePasses) {
			if (currentUser?.userId === gatePass.createdBy) {
				myTotalOpenGatePasses++;

				switch (gatePass.status) {
					case GatePassStatus.PENDING_APPROVAL: {
						myTotalPendingApprovals++;
						break;
					}
					case GatePassStatus.PENDING_CHECKOUT: {
						myTotalPendingCheckouts++;
						break;
					}
					case GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL:
					case GatePassStatus.CHANGE_REQUESTED_AT_CHECKOUT: {
						myTotalChangeRequests++;
						break;
					}
					case GatePassStatus.PENDING_RECEPTION:
					case GatePassStatus.PARTIALLY_RECEIVED: {
						myTotalPendingReturns++;
						if (isDateOlderThanToday(parseInt(gatePass.returnDate))) {
							myTotalPendingReturnOverdue++;
						}
						break;
					}
					default:
						break;
				}
			}

			if (currentUser?.userId === gatePass.approverUserId) {
				approverTotalOpenGatePasses++;

				switch (gatePass.status) {
					case GatePassStatus.PENDING_APPROVAL: {
						approverTotalPendingApprovals++;
						break;
					}
					case GatePassStatus.PENDING_CHECKOUT: {
						approverTotalPendingCheckouts++;
						break;
					}
					case GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL:
					case GatePassStatus.CHANGE_REQUESTED_AT_CHECKOUT: {
						approverTotalChangeRequests++;
						break;
					}
					case GatePassStatus.PENDING_RECEPTION:
					case GatePassStatus.PARTIALLY_RECEIVED: {
						approverTotalPendingReturns++;
						if (isDateOlderThanToday(parseInt(gatePass.returnDate))) {
							approverTotalPendingReturnOverdue++;
						}
						break;
					}
					default:
						break;
				}
			}
		}

		this.setState({
			myActiveGatePassStatItems: [
				{label: "Total Open Gate Passes", value: myTotalOpenGatePasses, type: "USER"},
				{label: "Pending Approvals", value: myTotalPendingApprovals, type: "USER"},
				{label: "Pending Checkouts", value: myTotalPendingCheckouts, type: "USER"},
				{label: "Change Requested", value: myTotalChangeRequests, type: "USER"},
				{label: "Pending Returns", value: myTotalPendingReturns, type: "USER"},
				{label: "Overdue Pending Returns", value: myTotalPendingReturnOverdue, type: "USER"}
			],
			approverActiveGatePassStatItems: [
				{label: "Total Open Gate Passes", value: approverTotalOpenGatePasses, type: "APPROVER"},
				{label: "Pending Approvals", value: approverTotalPendingApprovals, type: "APPROVER"},
				{label: "Pending Checkouts", value: approverTotalPendingCheckouts, type: "APPROVER"},
				{label: "Change Requested", value: approverTotalChangeRequests, type: "APPROVER"},
				{label: "Pending Returns", value: approverTotalPendingReturns, type: "APPROVER"},
				{label: "Overdue Pending Returns", value: approverTotalPendingReturnOverdue, type: "APPROVER"}
			]
		});
	};

	render(): React.ReactNode {
		const {myActiveGatePassStatItems, approverActiveGatePassStatItems, currentUser} = this.state;

		const isApproverStatsVisible = currentUser?.role && [UserRole.USER_LEVEL_0].includes(currentUser?.role);

		return <div className="dashboard-page-container">
			<div className="header">
				<div className="title">Dashboard</div>

				<div className="action-container"></div>
			</div>

			<div className="body">
				<div className='stat-title'>My Gate Pass Statistics</div>

				<div className="stat-card-items-container">
					{myActiveGatePassStatItems.map((stat, index) => (
						<StatCard title={stat.label} value={stat.value} type={stat.type}/>
					))}
				</div>

				<Divider/>

				{isApproverStatsVisible && <div className='stat-title'>Approver Gate Pass Statistics</div>}

				{isApproverStatsVisible && <div className="stat-card-items-container">
					{approverActiveGatePassStatItems.map((stat, index) => (
						<StatCard title={stat.label} value={stat.value} type={stat.type}/>
					))}
                </div>}
			</div>
		</div>;
	}
}