import React from 'react';
import './StatCard.scss';

export type StatCardType = "USER" | "APPROVER";

interface StatCardProps {
    title: string;
    value: string | number;
    type: StatCardType;
}

export const StatCard: React.FC<StatCardProps> = ({ title, value, type }) => {
    return (
        <div className={`stat-card stat-card-${type}`}>
            <div className="card-content">{value}</div>
            <div className={`card-header ${type}`}>{title}</div>
        </div>
    );
};