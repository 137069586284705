import { LazyDataCacheService } from "./LazyDataCacheService";
import { ItemSchema } from "./../admin/ItemManagerService";
import { CollectionReference, DocumentData, Firestore, Query, QueryFieldFilterConstraint, Transaction, arrayUnion, collection, doc, getDoc, getDocs, limit, orderBy, query, runTransaction, where } from "firebase/firestore";
import { Observable, Subject } from "rxjs";
import { DatabaseCollections, ServiceInitializationStatus, UserRole } from "../../defs/common";
import { FirebaseService } from "./FirebaseService";
import { AuthenticationService, AuthUser } from "./AuthenticationService";
import { ItemManagerService } from "../admin/ItemManagerService";


export enum GatePassType {
    RETURNABLE = "RETURNABLE",
    NON_RETURNABLE = "NON_RETURNABLE",
}

export enum GatePassItemStatus {
    PENDING_RECEPTION = "PENDING_RECEPTION",
    FULLY_RECEIVED = "FULLY_RECEIVED",
    PARTIALLY_RECEIVED = "PARTIALLY_RECEIVED",
    ISSUED = "ISSUED",
}

export enum GatePassStatus {
    PENDING_APPROVAL = "PENDING_APPROVAL",
    PENDING_CHECKOUT = "PENDING_CHECKOUT",
    REJECTED = "REJECTED",
    CHANGE_REQUESTED_AT_APPROVAL = "CHANGE_REQUESTED_AT_APPROVAL",
    CHANGE_REQUESTED_AT_CHECKOUT = "CHANGE_REQUESTED_AT_CHECKOUT",
    NON_RETURNABLE_ISSUED = "NON_RETURNABLE_ISSUED",
    PENDING_RECEPTION = "PENDING_RECEPTION",
    PARTIALLY_RECEIVED = "PARTIALLY_RECEIVED",
    FULLY_RECEIVED = "FULLY_RECEIVED",
    DELETED = "DELETED",
}

export enum GatePassAction {
    APPROVE = "APPROVE",
    REJECT = "REJECT",
    REQUEST_CHANGES_BY_APPROVER = "REQUEST_CHANGES_BY_APPROVER",
    REQUEST_CHANGES_BY_SECURITY_OFFICER = "REQUEST_CHANGES_BY_SECURITY_OFFICER",
    CHECKOUT = "CHECKOUT",
    MAKE_CHANGES = "MAKE_CHANGES",
}

interface GatePassItemReceptionHistoryEvent {
    receivedAt: number;
    receivedBy: string;
    quantity: number;
}

export interface GatePassItem {
    totalQuantity: number;
    serialNumber: string;
    remainingQuantity: number;
    itemCode: string;
    itemName: string;
    createdAt: number;
    createdBy: string;
    status: GatePassItemStatus;
    receptionHistory: GatePassItemReceptionHistoryEvent[];
    measurementUnit: string;
}

interface GatePassItemSchema {
    gatePassId: string;
    totalQuantity: number;
    remainingQuantity: number;
    itemCode: string;
    createdAt: number;
    createdBy: string;
    status: GatePassItemStatus;
    receptionHistory: GatePassItemReceptionHistoryEvent[];
}

interface GatePassItemSchemaWithDocId extends GatePassItemSchema {
    docId: string;
}

interface InitializationStatusEvent {
    status: ServiceInitializationStatus;
}

interface Item {
    quantity: number;
    itemCode: string;
}

export interface GatePassItemReceipt {
    quantity: number;
    itemCode: string;
}

interface GatePass {
    type: GatePassType;
    checkoutDate: number;
    returnDate: number;
    location: string;
    reason: string;
    holderUserId: string;
    vehicleNo: string;
    approverUserId: string;
    items: Item[],

}

export interface GatePassSchema {
    id: string;
    createdAt: number;
    createdBy: string;
    status: GatePassStatus;
    type: GatePassType;
    checkoutDate: number;
    returnDate: number | null;
    location: string;
    reason: string;
    holderUserId: string;
    vehicleNo: string | null;
    approverUserId: string;
    deletedBy: string | null;
    deletedAt: number | null;
}

export interface EditableGatePass {
    // checkoutDate: number;
    // returnDate: number | null;
    location: string;
    reason: string;
    vehicleNo: string | null;
    approverUserId: string;
}

export interface EditableGatePassWithItems extends EditableGatePass {
    items: EditableGatePassItem[];
}

export interface EditableGatePassItem {
    itemCode: string;
    quantity: number;
    isDeleted: boolean;
    isNewlyAdded: boolean;

}

export interface GatePassHistoryEvent {
    status: GatePassStatus;
    prevStatus: GatePassStatus | null;
    committedAt: number;
    committedABy: string;
}
interface GatePassHistoryEventSchema extends GatePassHistoryEvent {
    gatePassId: string;
}

export interface GatePassItemHistoryRecord extends GatePassItemSchema {
    gatePass: GatePassSchema;
}

export interface GatePassItemHistoryResult {
    history: GatePassItemHistoryRecord[];
    isAvailable: boolean;
}

interface GatePassDeletionUpdate {
    status: GatePassStatus;
    deletedBy: string | null;
    deletedAt: number | null;
}

export class GatePassManagerService {
    private static instance: GatePassManagerService | null;

    private readonly INACTIVE_GATE_PASS_STATUS: GatePassStatus[] = [
        GatePassStatus.NON_RETURNABLE_ISSUED,
        GatePassStatus.FULLY_RECEIVED,
        GatePassStatus.REJECTED,
        GatePassStatus.DELETED,
    ];
    private readonly AMENDABLE_GATE_PASS_STATUS: GatePassStatus[] = [
        GatePassStatus.PENDING_APPROVAL,
        GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL,
        GatePassStatus.CHANGE_REQUESTED_AT_CHECKOUT
    ];
    private readonly ACTIVE_GATE_PASS_STATUS: GatePassStatus[] = [
        GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL,
        GatePassStatus.CHANGE_REQUESTED_AT_CHECKOUT,
        GatePassStatus.PARTIALLY_RECEIVED,
        GatePassStatus.PENDING_APPROVAL,
        GatePassStatus.PENDING_CHECKOUT,
        GatePassStatus.PENDING_RECEPTION,
    ];
    private readonly DELETABLE_GATE_PASS_STATUS: GatePassStatus[] = [
        GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL,
        GatePassStatus.CHANGE_REQUESTED_AT_CHECKOUT,
        GatePassStatus.REJECTED,
        GatePassStatus.PENDING_APPROVAL,
        GatePassStatus.PENDING_CHECKOUT,
    ];

    private db: Firestore;
    private firebaseService: FirebaseService;
    private initializationStatusSubject: Subject<InitializationStatusEvent>;

    private activeGatePassDataCacheService: LazyDataCacheService<GatePassSchema>;
    private activeGatePassDataObservable?: Observable<GatePassSchema[]>;
    private inactiveGatePassDataCacheService: LazyDataCacheService<GatePassSchema>;
    private inactiveGatePassDataObservable?: Observable<GatePassSchema[]>;



    private constructor() {
        GatePassManagerService.instance = null;
        this.firebaseService = FirebaseService.getInstance();
        this.db = this.firebaseService.getDatabase();
        const activeGatePassQuery: Query = this.getActiveGatePassQuery();
        const inactiveGatePassQuery: Query = this.getInactiveGatePassQuery();
        this.activeGatePassDataCacheService = new LazyDataCacheService<GatePassSchema>(activeGatePassQuery);
        this.inactiveGatePassDataCacheService = new LazyDataCacheService<GatePassSchema>(inactiveGatePassQuery);

        this.initializationStatusSubject = new Subject<InitializationStatusEvent>();
    }

    private getActiveGatePassQuery = (): Query => {
        const gatePassCollectionRef: CollectionReference<DocumentData, DocumentData> = collection(this.db, DatabaseCollections.GATE_PASS);
        const activeGatePassFilter: QueryFieldFilterConstraint = where("status", "in", this.ACTIVE_GATE_PASS_STATUS);
        return query(gatePassCollectionRef, activeGatePassFilter);
    }

    private getInactiveGatePassQuery = (): Query => {
        const gatePassCollectionRef: CollectionReference<DocumentData, DocumentData> = collection(this.db, DatabaseCollections.GATE_PASS);
        const inactiveGatePassFilter: QueryFieldFilterConstraint = where("status", "in", this.INACTIVE_GATE_PASS_STATUS);
        return query(gatePassCollectionRef, inactiveGatePassFilter);
    }

    public getInitializationStatusObservable = (): Observable<InitializationStatusEvent> => {
        return this.initializationStatusSubject.asObservable();
    };

    public getGatePassDataObservable = (): Observable<GatePassSchema[]> => {

        if (!this.activeGatePassDataObservable) {
            throw new Error("Data Cache Observer is not initialized");
        }

        return this.activeGatePassDataObservable;
    }

    public static getInstance(): GatePassManagerService {
        if (!this.instance) {
            this.instance = new GatePassManagerService();
        }

        return this.instance;
    }

    public getAllActiveGatePasses = (): GatePassSchema[] => {
        return this.activeGatePassDataCacheService.getCachedDataAsAnArray()
    }

    public init = async (): Promise<void> => {

        let timeOut: NodeJS.Timeout | undefined;
        try {
            this.activeGatePassDataObservable = await this.activeGatePassDataCacheService.getDataObservable();
            // this.activeGatePassDataObservable.subscribe();
            timeOut = setTimeout(() => {
                this.initializationStatusSubject.next({ status: "DONE" });
                clearTimeout(timeOut);
            });
        } catch (error: any) {
            if (timeOut) {
                clearTimeout(timeOut);
            }

            timeOut = setTimeout(() => {
                this.initializationStatusSubject.next({ status: "ERROR" });
                clearTimeout(timeOut);
            });
        }

    };

    public createGatePass = async (gatePassData: GatePass): Promise<void> => {

        if (!gatePassData.items || gatePassData.items.length === 0) {
            throw new Error("Gate Pass must have at least one or more items");
        }

        const currentUser: AuthUser | null = AuthenticationService.getInstance().getCurrentUser();

        if (!currentUser) {
            throw new Error("User authentication failed!");
        }

        const gatePassId: string = await this.generateUniqueId();

        const gatePassCollection = collection(this.db, DatabaseCollections.GATE_PASS);
        const gatePassItemCollection = collection(this.db, DatabaseCollections.GATE_PASS_ITEM);
        const gatePassHistoryCollection = collection(this.db, DatabaseCollections.GATE_PASS_HISTORY);

        const createdAt: number = Date.now();
        const createdBy: string = currentUser.userId;
        const status: GatePassStatus = GatePassStatus.PENDING_APPROVAL;

        const gatePass: GatePassSchema = {
            approverUserId: gatePassData.approverUserId,
            holderUserId: gatePassData.holderUserId,
            checkoutDate: gatePassData.checkoutDate,
            location: gatePassData.location,
            reason: gatePassData.reason,
            type: gatePassData.type,
            vehicleNo: gatePassData.vehicleNo,
            returnDate: gatePassData.returnDate,
            createdAt,
            createdBy,
            id: gatePassId,
            status,
            deletedBy: null,
            deletedAt: null
        };

        const gatePassHistoryEvent: GatePassHistoryEventSchema = {
            committedABy: currentUser.userId,
            committedAt: createdAt,
            prevStatus: null,
            status,
            gatePassId
        };

        await runTransaction(this.db, async (transaction: Transaction) => {
            const gatePassDoc = doc(gatePassCollection, gatePassId);
            const gatePassHistoryDoc = doc(gatePassHistoryCollection);

            transaction.set(gatePassDoc, gatePass);
            transaction.set(gatePassHistoryDoc, gatePassHistoryEvent);

            for (const item of gatePassData.items) {
                const docRef = doc(gatePassItemCollection);
                const gatePassItem: GatePassItemSchema = {
                    createdAt,
                    gatePassId,
                    itemCode: item.itemCode,
                    status: gatePass.type === GatePassType.RETURNABLE ? GatePassItemStatus.PENDING_RECEPTION : GatePassItemStatus.ISSUED,
                    createdBy: currentUser.userId,
                    totalQuantity: item.quantity,
                    receptionHistory: [],
                    remainingQuantity: item.quantity
                };

                transaction.set(docRef, gatePassItem);
            }
        });
    };


    private generateUniqueId = async () => {
        const collRef = collection(this.db, DatabaseCollections.GATE_PASS);
        const q = query(collRef, orderBy('id', 'desc'), limit(1));
        const snapshot = await getDocs(q);
        let newId = 1; // Default to 1 if the collection is empty

        if (!snapshot.empty) {
            snapshot.forEach(doc => {
                const lastId = parseInt(doc.data().id, 10); // Ensure lastId is a number
                newId = lastId + 1;
            });
        }

        // Pad the newId to be 8 digits long
        const paddedId = String(newId).padStart(8, '0');
        return paddedId;
    };

    private approveGatePass = (gatePass: GatePassSchema): GatePassStatus => {
        const { status: currentStatus } = gatePass;

        if (currentStatus !== GatePassStatus.PENDING_APPROVAL) {
            throw new Error("Gate Pass must be in the Pending Approval status to approve.");
        }

        return GatePassStatus.PENDING_CHECKOUT;
    }

    private rejectGatePass = (gatePass: GatePassSchema): GatePassStatus => {
        const { status: currentStatus } = gatePass;

        if (currentStatus !== GatePassStatus.PENDING_APPROVAL) {
            throw new Error("Gate Pass must be in the Pending Approval status to reject.");
        }

        return GatePassStatus.REJECTED;
    }

    private requestChangesOfTheGatePass = (gatePass: GatePassSchema, requesterRole: UserRole): GatePassStatus => {
        const { status: currentStatus } = gatePass;

        if (![GatePassStatus.PENDING_APPROVAL, GatePassStatus.PENDING_CHECKOUT].includes(currentStatus)) {
            throw new Error("Gate Pass must be in the Pending Approval or Pending Checkout status to request changes.");
        }

        switch (requesterRole) {
            case UserRole.USER_LEVEL_0:
                return GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL;
            case UserRole.SECURITY_OFFICER:
                return GatePassStatus.CHANGE_REQUESTED_AT_CHECKOUT;
            default:
                throw new Error(`Requester user role is unauthorized to request changes: ${requesterRole}`);
        }

    }

    private makeChangesToTheGatePass = (gatePass: GatePassSchema): GatePassStatus => {
        const { status: currentStatus } = gatePass;

        if (currentStatus !== GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL) {
            throw new Error("Gate Pass must be in the Change Requested status to make changes.");
        }

        return GatePassStatus.PENDING_APPROVAL;
    }

    private checkoutTheGatePass = (gatePass: GatePassSchema): GatePassStatus => {
        const { status: currentStatus, type: gatePassType } = gatePass;

        if (currentStatus !== GatePassStatus.PENDING_CHECKOUT) {
            throw new Error("Gate Pass must be in the Pending Checkout status to checkout.");
        }

        if (gatePassType === GatePassType.NON_RETURNABLE) {
            return GatePassStatus.NON_RETURNABLE_ISSUED;
        } else {
            return GatePassStatus.PENDING_RECEPTION;
        }
    }

    private getTargetGatePassStatusByAction = async (gatePassAction: GatePassAction, gatePass: GatePassSchema): Promise<GatePassStatus> => {

        switch (gatePassAction) {
            case GatePassAction.APPROVE:
                return this.approveGatePass(gatePass);

            case GatePassAction.REJECT:
                return this.rejectGatePass(gatePass);

            case GatePassAction.REQUEST_CHANGES_BY_APPROVER:
                return this.requestChangesOfTheGatePass(gatePass, UserRole.USER_LEVEL_0);

            case GatePassAction.REQUEST_CHANGES_BY_SECURITY_OFFICER:
                return this.requestChangesOfTheGatePass(gatePass, UserRole.SECURITY_OFFICER);

            case GatePassAction.MAKE_CHANGES:
                return this.makeChangesToTheGatePass(gatePass);

            case GatePassAction.CHECKOUT:
                return this.checkoutTheGatePass(gatePass);

            default:
                throw new Error("Invalid");
        }
    }

    public commitGatePassAction = async (gatePassId: string, action: GatePassAction): Promise<void> => {

        if (!gatePassId) {
            throw new Error("Gate Pass ID is required to perform deletion.");
        }

        const currentUser: AuthUser | null = await AuthenticationService.getInstance().getCurrentUser();

        if (!currentUser) {
            throw new Error("User authentication failed.");
        }

        const gatePassDocRef = doc(this.db, DatabaseCollections.GATE_PASS, gatePassId);
        const gatePassDoc = await getDoc(gatePassDocRef);

        if (!gatePassDoc.exists) {
            throw new Error("Gate Pass not found");
        }

        const gatePass: GatePassSchema = gatePassDoc.data() as GatePassSchema;
        const committedAt: number = Date.now();
        const gatePassHistoryCollectionRef = collection(this.db, DatabaseCollections.GATE_PASS_HISTORY);
        const gatePassHistoryDocRef = doc(gatePassHistoryCollectionRef);
        const status: GatePassStatus = await this.getTargetGatePassStatusByAction(action, gatePass);

        const gatePassHistoryEvent: GatePassHistoryEventSchema = {
            committedABy: currentUser.userId,
            prevStatus: null,
            committedAt,
            status,
            gatePassId
        };

        await runTransaction(this.db, async (transaction: Transaction) => {
            transaction.update(gatePassDocRef, { status });
            transaction.set(gatePassHistoryDocRef, gatePassHistoryEvent);
        });
    };


    public getEventHistoryOfTheGatePass = async (gatePassId: string): Promise<GatePassHistoryEvent[]> => {

        if (!gatePassId) {
            throw new Error("Gate Pass ID is required");
        }

        const currentUser: AuthUser | null = await AuthenticationService.getInstance().getCurrentUser();

        if (!currentUser) {
            throw new Error("User authentication failed!");
        }

        const gatePassHistoryCollectionRef = collection(this.db, DatabaseCollections.GATE_PASS_HISTORY);
        const queryByGatePassId = query(gatePassHistoryCollectionRef, where("gatePassId", "==", gatePassId));
        const gatePassHistoryDocs = await getDocs(queryByGatePassId);

        if (gatePassHistoryDocs.empty) {
            return [];
        }

        return gatePassHistoryDocs.docs.map(doc => ({
            status: doc.data()?.status,
            prevStatus: doc.data().prevStatus,
            committedABy: doc.data().committedABy,
            committedAt: doc.data().committedAt,
        }));
    }


    public getItemsOfTheGatePass = async (gatePassId: string): Promise<GatePassItem[]> => {

        if (!gatePassId) {
            throw new Error("Gate Pass ID is required");
        }

        const currentUser: AuthUser | null = await AuthenticationService.getInstance().getCurrentUser();

        if (!currentUser) {
            throw new Error("User authentication failed!");
        }

        const gatePassItemCollectionRef = collection(this.db, DatabaseCollections.GATE_PASS_ITEM);
        const queryByGatePassId = query(gatePassItemCollectionRef, where("gatePassId", "==", gatePassId));
        const gatePassItemDocs = await getDocs(queryByGatePassId);

        if (gatePassItemDocs.empty) {
            return [];
        }

        const itemCodes: string[] = gatePassItemDocs.docs.map(doc => doc.data().itemCode);

        const items: ItemSchema[] = await ItemManagerService.getInstance().getItemsByItemCodes(itemCodes);
        const gatePassItems: GatePassItemSchema[] = gatePassItemDocs.docs.map(doc => ({
            ...doc.data()
        } as GatePassItemSchema));

        return itemCodes.map(itemCode => {
            const item: ItemSchema = items.find(_item => _item.itemCode === itemCode)!;
            const gatePassItem: GatePassItemSchema = gatePassItems.find(_item => _item.itemCode === itemCode)!;
            return ({
                createdAt: gatePassItem.createdAt,
                createdBy: gatePassItem.createdBy,
                status: gatePassItem.status,
                itemCode: gatePassItem.itemCode,
                itemName: item.itemName,
                totalQuantity: gatePassItem.totalQuantity,
                remainingQuantity: gatePassItem.remainingQuantity,
                receptionHistory: gatePassItem.receptionHistory,
                measurementUnit: item.measurementUnit,
                serialNumber: item.serialNumber
            })
        })
    }


    public handleReceptionOfGatePassItems = async (gatePassId: string, itemReceipts: GatePassItemReceipt[]): Promise<void> => {

        if (!gatePassId) {
            throw new Error("Gate Pass ID is required to perform deletion");
        }

        if (!itemReceipts || itemReceipts.length === 0) {
            throw new Error("No gate pass item(s)");
        }

        const currentUser: AuthUser | null = await AuthenticationService.getInstance().getCurrentUser();

        if (!currentUser) {
            throw new Error("User authentication failed!");
        }

        const gatePassDocRef = doc(this.db, DatabaseCollections.GATE_PASS, gatePassId);
        const gatePassDoc = await getDoc(gatePassDocRef);

        if (!gatePassDoc.exists) {
            throw new Error("Gate Pass not found");
        }

        const gatePassItemCollectionRef = collection(this.db, DatabaseCollections.GATE_PASS_ITEM);
        const queryByGatePassId = query(gatePassItemCollectionRef, where("gatePassId", "==", gatePassId));
        const gatePassItemDocs = await getDocs(queryByGatePassId);

        if (gatePassItemDocs.empty) {
            // TODO: LOG 
            throw new Error("No gate pass item(s) found");
        }

        const gatePassItems: GatePassItemSchemaWithDocId[] = gatePassItemDocs.docs.map(doc => ({
            ...doc.data(),
            docId: doc.id
        } as GatePassItemSchemaWithDocId));

        const gatePass: GatePassSchema = gatePassDoc.data() as GatePassSchema;

        if (gatePass.type === GatePassType.NON_RETURNABLE) {
            return;
        }

        const committedAt: number = Date.now();
        const gatePassHistoryCollectionRef = collection(this.db, DatabaseCollections.GATE_PASS_HISTORY);
        const gatePassHistoryDocRef = doc(gatePassHistoryCollectionRef);
        const receivedItems: Map<string, number> = new Map();
        const gatePassItemToStatusMap: Map<string, GatePassItemStatus> = new Map();

        itemReceipts.forEach(item => receivedItems.set(item.itemCode, item.quantity));

        await runTransaction(this.db, async (transaction: Transaction) => {

            for (const gatePassItem of gatePassItems) {

                if (!receivedItems.has(gatePassItem.itemCode)) {
                    gatePassItemToStatusMap.set(gatePassItem.itemCode, gatePassItem.status);
                    continue;
                }

                const receivedItemQuantity: number = receivedItems.get(gatePassItem.itemCode)!;

                if (receivedItemQuantity > gatePassItem.remainingQuantity) {
                    throw new Error("Received quantity must be less than remaining quantity");
                }

                let updatedItemStatus: GatePassItemStatus = gatePassItem.status;
                let updatedItemRemainingQuantity: number = gatePassItem.remainingQuantity;

                if (receivedItemQuantity < gatePassItem.remainingQuantity) {
                    updatedItemStatus = GatePassItemStatus.PARTIALLY_RECEIVED;
                    updatedItemRemainingQuantity = gatePassItem.remainingQuantity - receivedItemQuantity;
                } else if (receivedItemQuantity === gatePassItem.remainingQuantity) {
                    updatedItemStatus = GatePassItemStatus.FULLY_RECEIVED;
                    updatedItemRemainingQuantity = gatePassItem.remainingQuantity - receivedItemQuantity;
                }


                if (updatedItemRemainingQuantity !== gatePassItem.remainingQuantity || updatedItemStatus !== gatePassItem.status) {

                    const gatePassItemDocRef = doc(this.db, DatabaseCollections.GATE_PASS_ITEM, gatePassItem.docId);
                    const gatePassItemReceptionHistoryEvent: GatePassItemReceptionHistoryEvent = {
                        quantity: receivedItemQuantity,
                        receivedAt: committedAt,
                        receivedBy: currentUser.userId
                    };

                    transaction.update(gatePassItemDocRef, {
                        status: updatedItemStatus,
                        remainingQuantity: updatedItemRemainingQuantity,
                        receptionHistory: arrayUnion(gatePassItemReceptionHistoryEvent)
                    });

                }
                gatePassItemToStatusMap.set(gatePassItem.itemCode, updatedItemStatus);
            }

            const updatedStatusOfAllItems: GatePassItemStatus[] = Array.from(gatePassItemToStatusMap.values());
            const isAllItemsReceived: boolean = updatedStatusOfAllItems.every(status => status === GatePassItemStatus.FULLY_RECEIVED);
            const isNoItemReceived: boolean = updatedStatusOfAllItems.every(status => status === GatePassItemStatus.PENDING_RECEPTION);
            const isSomeItemsReceived: boolean = updatedStatusOfAllItems.some(status => [GatePassItemStatus.FULLY_RECEIVED, GatePassItemStatus.PARTIALLY_RECEIVED].includes(status));
            let status: GatePassStatus = gatePass.status;

            if (isAllItemsReceived) status = GatePassStatus.FULLY_RECEIVED;
            else if (isSomeItemsReceived) status = GatePassStatus.PARTIALLY_RECEIVED;
            else if (isNoItemReceived) status = GatePassStatus.PENDING_RECEPTION;

            const gatePassHistoryEvent: GatePassHistoryEventSchema = {
                committedABy: currentUser.userId,
                prevStatus: gatePass.status,
                committedAt,
                status,
                gatePassId
            };
            transaction.update(gatePassDocRef, { status });
            transaction.set(gatePassHistoryDocRef, gatePassHistoryEvent);
        });
    };

    public amendGatePass = async (gatePassId: string, editableGatePass: EditableGatePassWithItems): Promise<void> => {

        if (!gatePassId) {
            throw new Error("Gate Pass ID is required to perform deletion");
        }

        const currentUser: AuthUser | null = AuthenticationService.getInstance().getCurrentUser();

        if (!currentUser) {
            throw new Error("User authentication failed!");
        }

        const gatePassDocRef = doc(this.db, DatabaseCollections.GATE_PASS, gatePassId);
        const gatePassDoc = await getDoc(gatePassDocRef);


        if (!gatePassDoc.exists) {
            throw new Error("Gate Pass not found");
        }

        const gatePass: GatePassSchema = gatePassDoc.data() as GatePassSchema;
        const isAmendableGatePass: boolean = this.AMENDABLE_GATE_PASS_STATUS.includes(gatePass.status);
        const isInactiveGatePass: boolean = this.INACTIVE_GATE_PASS_STATUS.includes(gatePass.status);

        if (isInactiveGatePass || !isAmendableGatePass) {
            throw new Error("Gate pass cannot be amended");
        }

        const isAllGatePassItemsDeleted: boolean = editableGatePass.items.every(item => item.isDeleted);

        if (editableGatePass.items.length === 0 || isAllGatePassItemsDeleted) {
            throw new Error("Gate pass must have one or more items");
        }

        const gatePassItemCollectionRef = collection(this.db, DatabaseCollections.GATE_PASS_ITEM);
        const gatePassHistoryCollectionRef = collection(this.db, DatabaseCollections.GATE_PASS_HISTORY);
        const queryByGatePassId = query(gatePassItemCollectionRef, where("gatePassId", "==", gatePassId));
        const gatePassItemDocs = await getDocs(queryByGatePassId);
        const createdAt: number = Date.now();
        const createdBy: string = currentUser.userId;

        const gatePassDataToBeUpdated: EditableGatePass = {
            approverUserId: editableGatePass.approverUserId,
            // checkoutDate: editableGatePass.checkoutDate,
            location: editableGatePass.location,
            reason: editableGatePass.reason,
            // returnDate: editableGatePass.returnDate,
            vehicleNo: editableGatePass.vehicleNo,
        }

        const gatePassItemCodeToDocIdMap: Map<string, string> = new Map();
        gatePassItemDocs.forEach(doc => gatePassItemCodeToDocIdMap.set(doc.data().itemCode, doc.id));

        const newGatePassItems: GatePassItemSchema[] = editableGatePass.items.filter(item => item.isNewlyAdded).map(item => ({
            createdAt,
            createdBy,
            gatePassId,
            itemCode: item.itemCode,
            receptionHistory: [],
            remainingQuantity: item.quantity,
            status: gatePass.type === GatePassType.RETURNABLE ? GatePassItemStatus.PENDING_RECEPTION : GatePassItemStatus.ISSUED,
            totalQuantity: item.quantity
        }));

        const deletionRequiredGatePassItemDocIds: string[] = editableGatePass.items
            .filter(item => (item.isDeleted && gatePassItemCodeToDocIdMap.has(item.itemCode)))
            .map(item => gatePassItemCodeToDocIdMap.get(item.itemCode)!);

        const amendedGatePassItems: EditableGatePassItem[] = editableGatePass.items.filter(item => !item.isDeleted && !item.isNewlyAdded);

        await runTransaction(this.db, async (transaction: Transaction) => {
            // Newly Added Gate Pass Items
            for (const newGatePassItem of newGatePassItems) {
                const gatePassItemDocRef = doc(gatePassItemCollectionRef);
                transaction.set(gatePassItemDocRef, { ...newGatePassItem });
            }

            // Deleted Gate Pass Items
            for (const deletionRequiredDocId of deletionRequiredGatePassItemDocIds) {
                const gatePassItemDocRef = doc(gatePassItemCollectionRef, deletionRequiredDocId);
                transaction.delete(gatePassItemDocRef);
            }

            // Edited Gate Pass Items
            for (const amendedGatePassItem of amendedGatePassItems) {
                const amendedGatePassItemDocId: string | undefined = gatePassItemCodeToDocIdMap.get(amendedGatePassItem.itemCode);

                if (!amendedGatePassItemDocId) {
                    throw new Error(`Cannot find a document corresponding to the Item Code: ${amendedGatePassItem.itemCode}`);
                }

                const gatePassItemDocRef = doc(gatePassItemCollectionRef, amendedGatePassItemDocId);
                transaction.update(gatePassItemDocRef, { totalQuantity: amendedGatePassItem.quantity, remainingQuantity: amendedGatePassItem.quantity });
            }

            // Gate Pass Data
            let status: GatePassStatus = gatePass.status;
            const changesAllowedStatus: GatePassStatus[] = [GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL, GatePassStatus.CHANGE_REQUESTED_AT_CHECKOUT];
            if (changesAllowedStatus.includes(gatePass.status)) {
                status = GatePassStatus.PENDING_APPROVAL;

                const gatePassHistoryEvent: GatePassHistoryEventSchema = {
                    committedABy: currentUser.userId,
                    committedAt: createdAt,
                    prevStatus: gatePass.status,
                    status,
                    gatePassId
                };

                const gatePassHistoryDocRef = doc(gatePassHistoryCollectionRef);
                transaction.set(gatePassHistoryDocRef, gatePassHistoryEvent);
            }

            transaction.update(gatePassDocRef, { ...gatePassDataToBeUpdated, status });
        });

    }

    public queryItemHistoryByItemCode = async (itemCode: string): Promise<GatePassItemHistoryResult> => {

        if (!itemCode) {
            return { isAvailable: false, history: [] };
        }

        const gatePassCollectionRef = collection(this.db, DatabaseCollections.GATE_PASS);
        const gatePassItemCollectionRef = collection(this.db, DatabaseCollections.GATE_PASS_ITEM);
        const queryByGateItemCode = query(gatePassItemCollectionRef, where("itemCode", "==", itemCode));
        const gatePassItemDocs = await getDocs(queryByGateItemCode);

        if (gatePassItemDocs.empty) {
            return { isAvailable: false, history: [] };

        }

        const gatePassItemHistoryRecords: GatePassItemHistoryRecord[] = [];

        for (const gatePassItemDoc of gatePassItemDocs.docs) {
            const gatePassId: string = gatePassItemDoc.data().gatePassId ?? "";

            if (!gatePassId) {
                throw new Error("Gate Pass ID is not specified in the Gate Pass Item Data");
            }

            const gatePassDocRef = doc(gatePassCollectionRef, gatePassId);
            const gatePassDoc = await getDoc(gatePassDocRef);

            if (!gatePassDoc.exists) {
                throw new Error("Gate Pass Item Data does not exist");
            }

            const gatePassItemHistoryRecord: GatePassItemHistoryRecord = {
                gatePass: gatePassDoc.data() as GatePassSchema,
                createdAt: gatePassItemDoc.data().createdAt,
                createdBy: gatePassItemDoc.data().createdBy,
                gatePassId: gatePassItemDoc.data().gatePassId,
                itemCode: gatePassItemDoc.data().itemCode,
                receptionHistory: gatePassItemDoc.data().receptionHistory,
                remainingQuantity: gatePassItemDoc.data().remainingQuantity,
                status: gatePassItemDoc.data().status,
                totalQuantity: gatePassItemDoc.data().totalQuantity,
            };

            gatePassItemHistoryRecords.push(gatePassItemHistoryRecord);
        }

        const isItemNotAvailable: boolean = gatePassItemHistoryRecords.some(gatePassItem => [GatePassItemStatus.PARTIALLY_RECEIVED, GatePassItemStatus.PENDING_RECEPTION].includes(gatePassItem.status))

        const gatePassItemHistoryResult: GatePassItemHistoryResult = {
            history: gatePassItemHistoryRecords,
            isAvailable: !isItemNotAvailable
        };


        return gatePassItemHistoryResult;
    }

    public getInactiveGatePassDataObservable = async (): Promise<Observable<GatePassSchema[]>> => {

        if (!this.inactiveGatePassDataObservable) {
            this.inactiveGatePassDataObservable = await this.inactiveGatePassDataCacheService.getDataObservable();
        }

        return Promise.resolve(this.inactiveGatePassDataObservable);
    }

    public deleteGatePass = async (gatePassId: string): Promise<void> => {
        if (!gatePassId) {
            throw new Error("Gate Pass ID is required to perform deletion");
        }

        const currentUser: AuthUser | null = AuthenticationService.getInstance().getCurrentUser();

        if (!currentUser) {
            throw new Error("User authentication failed!");
        }

        const gatePassDocRef = doc(this.db, DatabaseCollections.GATE_PASS, gatePassId);
        const gatePassDoc = await getDoc(gatePassDocRef);


        if (!gatePassDoc.exists) {
            throw new Error("Gate Pass not found");
        }

        const gatePass: GatePassSchema = gatePassDoc.data() as GatePassSchema;
        const isDeletable: boolean = this.DELETABLE_GATE_PASS_STATUS.includes(gatePass.status);

        if (!isDeletable) {
            throw new Error("Gate pass cannot be deleted");
        }

        const gatePassHistoryCollectionRef = collection(this.db, DatabaseCollections.GATE_PASS_HISTORY);
        const timestamp: number = Date.now();
        let status: GatePassStatus = GatePassStatus.DELETED;
        const gatePassHistoryEvent: GatePassHistoryEventSchema = {
            committedABy: currentUser.userId,
            committedAt: timestamp,
            prevStatus: gatePass.status,
            status,
            gatePassId
        };

        const gatePassData: GatePassDeletionUpdate = {
            deletedBy: currentUser.userId,
            deletedAt: timestamp,
            status
        };

        const gatePassHistoryDocRef = doc(gatePassHistoryCollectionRef);

        await runTransaction(this.db, async (transaction: Transaction) => {
            transaction.set(gatePassHistoryDocRef, gatePassHistoryEvent);
            transaction.update(gatePassDocRef, { ...gatePassData });
        });
    }




}

// Fully Received, Non-returnable issued, Rejected
// TODO: Add a query to get the inactive gate pass