import * as XLSX from 'xlsx';
import { UserRole } from "../defs/common";
import { UserManagerService } from "../services/admin/UserManagerService";
import { GatePassHistoryEvent, GatePassItemStatus, GatePassStatus } from "../services/common/GatePassManagerService";

export function getDefaultPathForUser(role: UserRole): string {
    switch (role) {
        case UserRole.ADMIN:
        case UserRole.USER_LEVEL_0:
        case UserRole.USER_LEVEL_1:
            return "/dashboard";
        case UserRole.SECURITY_OFFICER:
        case UserRole.TOOL_ROOM_OFFICER:
        default:
            return "/gate-passes";
    }
}

function formatDateTimeForFileName(): string {
    const date: Date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
}

export function getGatePassTypeDisplayValue(type: string) {
    switch (type) {
        case "RETURNABLE":
            return "Returnable";
        case "NON_RETURNABLE":
            return "Non Returnable";
        default:
            return type;
    }
}

export function getGatePassStatusDisplayValue(status: GatePassStatus) {
    switch (status) {
        case GatePassStatus.PENDING_APPROVAL:
            return "Pending Approval";
        case GatePassStatus.PENDING_CHECKOUT:
            return "Pending Checkout";
        case GatePassStatus.REJECTED:
            return "Rejected";
        case GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL:
            return "Change Requested at Approval";
        case GatePassStatus.CHANGE_REQUESTED_AT_CHECKOUT:
            return "Change Requested at Checkout";
        case GatePassStatus.NON_RETURNABLE_ISSUED:
            return "Non-returnable Issued";
        case GatePassStatus.PENDING_RECEPTION:
            return "Pending Return";
        case GatePassStatus.PARTIALLY_RECEIVED:
            return "Partially Received";
        case GatePassStatus.FULLY_RECEIVED:
            return "Fully Received";
        case GatePassStatus.DELETED:
            return "Deleted";
        default:
            return status;
    }
}

export function getGatePassItemStatusDisplayValue(status: GatePassItemStatus) {
    switch (status) {
        case GatePassItemStatus.ISSUED:
            return "Issued";
        case GatePassItemStatus.PENDING_RECEPTION:
            return "Pending Return";
        case GatePassItemStatus.PARTIALLY_RECEIVED:
            return "Partially Received";
        case GatePassItemStatus.FULLY_RECEIVED:
            return "Fully Received";
        default:
            return status;
    }
}

export function getGatePassTimeLineStatusDisplayValue(historyDataItem: GatePassHistoryEvent) {
    const status = historyDataItem.status;
    const prevStatus = historyDataItem.prevStatus;
    switch (status) {
        case GatePassStatus.PENDING_APPROVAL:
            return prevStatus === null ? "Created" : "Edited";
        case GatePassStatus.PENDING_CHECKOUT:
            return "Approved";
        case GatePassStatus.REJECTED:
            return "Rejected";
        case GatePassStatus.CHANGE_REQUESTED_AT_APPROVAL:
            return "Returned by the Approver";
        case GatePassStatus.CHANGE_REQUESTED_AT_CHECKOUT:
            return "Returned by Security Officer";
        case GatePassStatus.NON_RETURNABLE_ISSUED:
            return "Issued";
        case GatePassStatus.PENDING_RECEPTION:
            return "Checked Out";
        case GatePassStatus.PARTIALLY_RECEIVED:
            return "Partially Received";
        case GatePassStatus.FULLY_RECEIVED:
            return "Fully Received";
        case GatePassStatus.DELETED:
            return "Deleted";
        default:
            return status;
    }
};

export function getUnitOfMeasurements(): { value: string, label: string; }[] {
    return [
        { value: "NOS", label: "Nos" },
        { value: "PIECES", label: "Pieces" },
        { value: "METERS", label: "Meters" },
        { value: "LITERS", label: "Liters" },
        { value: "KG", label: "Kilo Grams" },
        { value: "INCHES", label: "Inches" },
        { value: "FEET", label: "Feet" },
        { value: "EACH", label: "Each" },
        { value: "DOZEN", label: "Dozen" }
    ];
}

export function getUnitOfMeasurementDisplayValue(value: string): string {
    const allUOM = getUnitOfMeasurements();
    for (let item of allUOM) {
        if (item.value === value) {
            return item.label;
        }
    }
    return value;
}

export function getUserRoles(): { value: UserRole, label: string; }[] {
    return [
        { value: UserRole.ADMIN, label: "Admin" },
        { value: UserRole.USER_LEVEL_0, label: "Approver" },
        { value: UserRole.USER_LEVEL_1, label: "User" },
        { value: UserRole.GUEST_USER, label: "Guest User" },
        { value: UserRole.SECURITY_OFFICER, label: "Security Officer" },
        { value: UserRole.TOOL_ROOM_OFFICER, label: "Tool Room Officer" }
    ];
}

export function getUserRoleDisplayValue(value: UserRole): string {
    const allUserRoles = getUserRoles();
    for (let item of allUserRoles) {
        if (item.value === value) {
            return item.label;
        }
    }
    return value;
}

export function formatDate(epoch: number) {
    if (!epoch) return "";
    const date = new Date(epoch * 1000);
    return date.toLocaleDateString();
}

export function isDateOlderThanToday(epoch: number): boolean {
    if (!epoch) return false;

    const inputDate = new Date(epoch * 1000);
    const today = new Date();

    // Reset time to midnight for both dates
    inputDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return inputDate < today;
}

export function formatDateEpoch(epoch: number) {
    if (!epoch) return "";
    const date = new Date(epoch);
    return date.toLocaleDateString();
}

export function formatDateTimeEpoch(epoch: number) {
    if (!epoch) return "";
    const date = new Date(epoch);
    return date.toLocaleString();
}

export function exportToExcel(data: any[], specificFileName?: string, customHeaders?: { [key: string]: string; }) {
    const fileName = `${specificFileName ?? "export"}-${formatDateTimeForFileName()}.xlsx`;
    try {
        const formattedData = customHeaders ? data.map(row => {
            const formattedRow: { [key: string]: any; } = {};
            for (const [key, value] of Object.entries(row)) {
                formattedRow[customHeaders[key] || key] = value;
            }
            return formattedRow;
        }) : data;

        const ws = XLSX.utils.json_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, fileName);
    } catch (error) {
        console.error("Error in exporting excel", error ?? "");
    }
};

export function getGatePassPDFFileName(gatePassNo: string) {
    return `gate-pass-${gatePassNo}-${formatDateTimeForFileName()}.pdf`;
};

export function getUserNameById(userId: string) {
    return UserManagerService.getInstance().getUserNameByUserId(userId);
}

export function getDifferenceInDays(timestamp1: number, timestamp2: number): number {
    const msInDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    // Calculate the absolute difference in milliseconds
    const diffInMs = timestamp1 - timestamp2;

    // Convert milliseconds to days
    return Math.floor(diffInMs / msInDay);
}

export function getDifferenceInHours(timestamp1: number, timestamp2: number): number {
    const msInHour = 60 * 60 * 1000; // Number of milliseconds in an hour

    // Calculate the absolute difference in milliseconds
    const diffInMs = timestamp1 - timestamp2;

    console.log("diffInMs / msInHour", diffInMs / msInHour);

    // Convert milliseconds to hours
    return Math.floor(diffInMs / msInHour);
}