import { notification } from "antd";
import React from "react";
import './Toast.scss';

interface ToastProps { }

interface ToastState { }

export class Toast extends React.Component<ToastProps, ToastState> {
    static success(message: React.ReactNode, description?: React.ReactNode, duration?: number) {
        notification.success({
            message: message || 'Success',
            description: description,
            duration: duration
        });
    }

    static warn(message: React.ReactNode, description?: React.ReactNode, duration?: number) {
        notification.warning({
            message: message || 'Warning',
            description: description,
            duration: duration
        });
    }

    static info(message: React.ReactNode, description?: React.ReactNode, duration?: number) {
        notification.info({
            message: message || 'Information',
            description: description,
            duration: duration
        });
    }

    static error(message: React.ReactNode, description?: React.ReactNode, duration?: number) {
        notification.error({
            message: message || 'Error',
            description: description,
            duration: duration
        });
    }

    render() {
        return null;
    }
}