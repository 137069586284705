import React from "react";
import { Subscription } from "rxjs";
import { EditGatePassForm } from "../../components/GatePassForms/EditGatePassForm/EditGatePassForm";
import { GenericGatePassTable } from "../../components/GenericGatePassTable/GenericGatePassTable";
import { GenericModal } from "../../components/GenericModal/GenericModal";
import { AuthenticationService, AuthUser } from "../../services/common/AuthenticationService";
import { GatePassManagerService } from "../../services/common/GatePassManagerService";
import { formatDate, getGatePassTypeDisplayValue, getUserNameById } from "../../utils/utils";

const GP_MODAL_WIDTH = 700;

interface BaseGatePassPageProps { }

interface BaseGatePassPageState {

    gatePasses: any[];
    showGatePassActionModal: boolean;
    isGatePassDataLoading: boolean;
    selectedRow: any;
    currentGatePassId: string;
    showEditGatePassDialog: boolean;
}

export class BaseGatePassPage extends React.Component<BaseGatePassPageProps, BaseGatePassPageState> {
    private gatePassDataSubscription?: Subscription;
    private isInitialized: boolean;
    protected currentUser: AuthUser;


    constructor(props: BaseGatePassPageProps) {
        super(props);

        this.isInitialized = false;
        this.currentUser = AuthenticationService.getInstance().getCurrentUser();

        this.state = {
            gatePasses: [],
            showGatePassActionModal: false,
            isGatePassDataLoading: false,
            selectedRow: {},
            currentGatePassId: "",
            showEditGatePassDialog: false
        };
    }

    private initGatePassDataSubscription = (): void => {
        this.setState({ isGatePassDataLoading: true });

        this.gatePassDataSubscription = GatePassManagerService.getInstance().getGatePassDataObservable().subscribe(gatePassData => {

            if (!this.isInitialized) {
                this.setState({ isGatePassDataLoading: false });
            }

            const processedGatePasses: any[] = this.processGatePasses(gatePassData);
            this.isInitialized = true;
            this.setState({ gatePasses: processedGatePasses });
        });
    };

    protected processGatePasses = (gatePasses: any[]): any[] => {
        return gatePasses;
    };

    protected onGatePassView = (data: any): void => {
        const fullData = {
            ...data,
            type: getGatePassTypeDisplayValue(data.type),
            approverName: getUserNameById(data.approverUserId),
            holderName: getUserNameById(data.holderUserId),
            checkOutDate: formatDate(data.checkoutDate),
            returnDate: formatDate(data.returnDate)
        };

        this.setState({ showGatePassActionModal: true, selectedRow: fullData, currentGatePassId: data.id });
    };

    protected getGatePassActionForm = (): JSX.Element => {
        return <div></div>;
    };

    protected closeGatePassActionModal = (): void => {
        this.setState({ showGatePassActionModal: false });
    };

    protected getPageContainerClassName = (): string => {
        return "";
    };

    protected getPageTitle = (): string => {
        return "";
    };

    protected isEditAllowed = (): boolean => {
        return false;
    };

    protected isDeleteAllowed = (): boolean => {
        return false;
    };

    private onGatePassEdit = (data: any) => {
        const fullData = {
            ...data,
            type: data.type,
            approverName: getUserNameById(data.approverUserId),
            holderName: getUserNameById(data.holderUserId),
            checkOutDate: formatDate(data.checkoutDate),
            returnDate: formatDate(data.returnDate)
        };

        this.setState({ showEditGatePassDialog: true, selectedRow: fullData, currentGatePassId: data.id });
    };

    private closeEditGatePassModal = (): void => {
        this.setState({ showEditGatePassDialog: false, selectedRow: undefined, currentGatePassId: "" });
    };

    componentDidMount(): void {
        try {
            this.initGatePassDataSubscription();
        } catch (error: any) {
            // TODO: Log
        }
    }

    render(): React.ReactNode {
        const { gatePasses, showGatePassActionModal, currentGatePassId, selectedRow, showEditGatePassDialog } = this.state;
        const pageContainerClassName: string = this.getPageContainerClassName();

        return <div className={pageContainerClassName}>
            <div className="header">
                <div className="title">{this.getPageTitle()}</div>
            </div>

            <div className="body">
                <div className="base-gate-pass-table-container">
                    <GenericGatePassTable
                        gatePassRows={gatePasses}
                        onGatePassView={this.onGatePassView}
                        isEditAllowed={this.isEditAllowed()}
                        isDeleteAllowed={this.isDeleteAllowed()}
                        onGatePassEdit={this.onGatePassEdit}
                    />
                    <GenericModal
                        title={`Gate Pass: ${currentGatePassId}`}
                        open={showGatePassActionModal}
                        onCancel={this.closeGatePassActionModal}
                        width={GP_MODAL_WIDTH}
                    >
                        {this.getGatePassActionForm()}
                    </GenericModal>

                    <GenericModal
                        title={`Edit Gate Pass: ${currentGatePassId}`}
                        open={showEditGatePassDialog}
                        onCancel={this.closeEditGatePassModal}
                        width={GP_MODAL_WIDTH}
                    >
                        <EditGatePassForm
                            gatePassNo={currentGatePassId ?? ""}
                            currentData={{ ...selectedRow }}
                            onClose={this.closeEditGatePassModal}
                        />
                    </GenericModal>
                </div>
            </div>
        </div>;
    }
}