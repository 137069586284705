import { DocumentData, FirestoreError, onSnapshot, Query, QuerySnapshot, Unsubscribe } from "firebase/firestore";
import { BehaviorSubject, Observable } from "rxjs";


export class LazyDataCacheService<DataItem> {


    protected data: Map<string, DataItem>;
    protected isInitialized: boolean;
    protected dataSubject: BehaviorSubject<DataItem[]>;
    protected unsubscribe?: Unsubscribe;
    private databaseQuery: Query<DocumentData, DocumentData>;
    private onDataEvent?: (data: DataItem[]) => void;


    constructor(query: Query<DocumentData, DocumentData>) {
        this.data = new Map<string, DataItem>();
        this.isInitialized = false;
        this.dataSubject = new BehaviorSubject<DataItem[]>([]);
        this.databaseQuery = query;

    }

    protected setDatabaseQuery = (query: Query<DocumentData, DocumentData>): void => { }

    private handleSnapshotError = (error: FirestoreError): void => {
        console.error("LazyDataCacheService > handleSnapshotError() => Snapshot Error:", error);
    }

    private handleQuerySnapshot = (snapshot: QuerySnapshot): void => {
        for (const docChange of snapshot.docChanges()) {
            switch (docChange.type) {
                case "added":
                case "modified":
                    this.data.set(docChange.doc.id, { ...docChange.doc.data() } as DataItem);
                    break;
                case "removed":
                    this.data.delete(docChange.doc.id);
                    break;
                default:
                    break;
            }
        }

        const data: DataItem[] = this.getCachedDataAsAnArray();
        this.onDataEvent?.(data)
        this.dataSubject.next(data)
    }

    public getCachedDataAsAnArray = (): DataItem[] => {
        return Array.from(this.data.values())
    }

    private initSnapshotListener = async (onError?: (error: any) => void): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (this.unsubscribe) {
                this.unsubscribe();
            }

            this.unsubscribe = onSnapshot(
                this.databaseQuery,
                (snapshot: QuerySnapshot<DocumentData, DocumentData>) => {
                    this.handleQuerySnapshot(snapshot);

                    if (!this.isInitialized) {
                        resolve();
                    }

                    return;
                },
                (error: FirestoreError) => {
                    this.handleSnapshotError(error);
                    onError?.(error);

                    if (!this.isInitialized) {
                        reject(error);
                    }

                    return;
                }
            );
        });
    }

    public getDataObservable = async (onError?: (error: any) => void, onDataEvent?: (data: DataItem[]) => void): Promise<Observable<DataItem[]>> => {
        if (this.isInitialized) {
            return Promise.resolve(this.dataSubject.asObservable());
        }

        try {
            this.onDataEvent = onDataEvent;
            await this.initSnapshotListener(onError);
            this.isInitialized = true;
            return Promise.resolve(this.dataSubject.asObservable());
        } catch (error: any) {
            this.isInitialized = false;
            return Promise.reject(error);
        }

    }
}