import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { Button, Result } from "antd";
import React from "react";
import { GatePassHistoryEvent, GatePassItem, GatePassManagerService } from "../../services/common/GatePassManagerService";
import { GatePassPDFDocument } from "./components/GatePassPDFDocument";
import './GatePassDownloadView.scss';

export interface GatePassDownLoadViewData {
    gatePassNo: string;
    holderUser: string;
    createdByUser: string;
    approverUser: string;
    type: string;
    checkoutDate: string;
    returnDate: string;
    location: string;
    reason: string;
    vehicleNo: string;
    status: string;
    createdAt: string;
}

interface GatePassDownloadViewProps {
    gatePassData: GatePassDownLoadViewData;
}

interface GatePassDownloadViewState {
    gatePassItems: GatePassItem[],
    gatePassHistory: GatePassHistoryEvent[],
    isGatePassItemsLoading: boolean,
    isGatePassHistoryLoading: boolean;
}

export class GatePassDownloadView extends React.Component<GatePassDownloadViewProps, GatePassDownloadViewState> {
    constructor(props: GatePassDownloadViewProps) {
        super(props);

        this.state = {
            gatePassItems: [],
            gatePassHistory: [],
            isGatePassItemsLoading: false,
            isGatePassHistoryLoading: false
        };
    }

    componentDidMount(): void {
        this.getGatePassItemData(this.props.gatePassData.gatePassNo);
        this.getGatePassHistoryData(this.props.gatePassData.gatePassNo);
    }

    componentDidUpdate(prevProps: Readonly<GatePassDownloadViewProps>): void {
        if (prevProps.gatePassData !== this.props.gatePassData) {
            this.getGatePassItemData(this.props.gatePassData.gatePassNo);
            this.getGatePassHistoryData(this.props.gatePassData.gatePassNo);
        }
    }

    private getGatePassItemData = (gatePassNo: string) => {
        this.setState({ gatePassItems: [], isGatePassItemsLoading: true });
        GatePassManagerService.getInstance().getItemsOfTheGatePass(gatePassNo).then((data: GatePassItem[]) => {
            this.setState({ gatePassItems: data, isGatePassItemsLoading: false });
        }).catch((error: any) => {
            console.error("Error retrieving gate pass items", error ?? undefined);
            this.setState({ gatePassItems: [], isGatePassItemsLoading: false });
        });
    };

    private getGatePassHistoryData = (gatePassNo: string) => {
        this.setState({ gatePassHistory: [], isGatePassHistoryLoading: true });
        GatePassManagerService.getInstance().getEventHistoryOfTheGatePass(gatePassNo).then((data: GatePassHistoryEvent[]) => {
            this.setState({ gatePassHistory: data.sort((a, b) => a.committedAt - b.committedAt), isGatePassHistoryLoading: false });
        }).catch((error: any) => {
            console.error("Error retrieving gate pass history", error ?? undefined);
            this.setState({ gatePassHistory: [], isGatePassHistoryLoading: false });
        });
    };

    private getMessageTitle = (): string => {
        const { isGatePassItemsLoading, isGatePassHistoryLoading } = this.state;
        if (isGatePassItemsLoading || isGatePassHistoryLoading) {
            return "Loading Gate Pass details...";
        }
        return "Gate Pass details are ready";
    };

    private getSubMessageTitle = (): string => {
        const { isGatePassItemsLoading, isGatePassHistoryLoading } = this.state;
        if (isGatePassItemsLoading || isGatePassHistoryLoading) {
            return "Please wait";
        }
        return "Please click the button to download or print the Gate Pass";
    };

    private handlePrint = async () => {
        const { gatePassData } = this.props;
        const { gatePassItems, gatePassHistory } = this.state;

        try {
            // Generate the PDF as a blob
            const blob = await pdf(
                <GatePassPDFDocument
                    gatePassData={gatePassData}
                    gatePassItems={gatePassItems}
                    gatePassHistory={gatePassHistory}
                />
            ).toBlob();

            // Create a URL from the Blob
            const url = URL.createObjectURL(blob);

            // Open the PDF in a new window
            const newWindow = window.open(url);

            // Automatically trigger the print dialog when the window loads
            if (newWindow) {
                newWindow.onload = () => {
                    newWindow.print();
                };
            }
        } catch (error) {
            console.error("Error generating the PDF for printing", error);
        }
    };

    render(): React.ReactNode {
        const { gatePassData } = this.props;
        const { gatePassItems, gatePassHistory, isGatePassItemsLoading, isGatePassHistoryLoading } = this.state;

        return (
            <div>
                <Result
                    status={isGatePassItemsLoading || isGatePassHistoryLoading ? "info" : "success"}
                    title={this.getMessageTitle()}
                    subTitle={this.getSubMessageTitle()}
                    extra={[
                        <PDFDownloadLink
                            document={
                                <GatePassPDFDocument
                                    gatePassData={gatePassData}
                                    gatePassItems={gatePassItems}
                                    gatePassHistory={gatePassHistory}
                                />
                            }
                            fileName={`gate-pass-${gatePassData.gatePassNo}.pdf`}
                            key="download-link"
                        >
                            {({ blob, url, loading, error }) => (
                                <Button
                                    className="download-btn"
                                    type="primary"
                                    size="small"
                                    icon={<DownloadOutlined />}
                                    loading={loading}
                                    disabled={isGatePassItemsLoading || isGatePassHistoryLoading}
                                >
                                    Download PDF
                                </Button>
                            )}
                        </PDFDownloadLink>,
                        <Button
                            key="print-button"
                            className="print-btn"
                            type="primary"
                            size="small"
                            icon={<PrinterOutlined />}
                            onClick={this.handlePrint}
                            disabled={isGatePassItemsLoading || isGatePassHistoryLoading}
                        >
                            Print PDF
                        </Button>
                    ]}
                />
            </div>
        );
    }
}