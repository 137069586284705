import React from "react";
import { Button, Form, FormInstance, InputNumber } from "antd";
import { UserManagerService } from "../../services/admin/UserManagerService";
import { Toast } from "../Toast/Toast";

interface UserEditFormProps {
	currentUser: any;
	onClose: () => void;
}

interface UserEditFormState {
	inProgress: boolean;
}

export class UserEditForm extends React.Component<UserEditFormProps, UserEditFormState> {
	private formRef: React.RefObject<FormInstance>;

	constructor(props: UserEditFormProps) {
		super(props);
		this.state = {
			inProgress: false
		};

		this.formRef = React.createRef();
	}

	private onFinish = async (values: any) => {
		const { currentUser, onClose } = this.props;
		if (!values || !currentUser || !currentUser.userId) {
			Toast.error("Missing form fields", "Please fill all the required fields");
			console.error("Missing form fields");
			return;
		}

		this.setState({ inProgress: true });

		UserManagerService.getInstance()
			.updateUserData(currentUser.userId, {
				userConfig: {
					maxGatePassesInPendingReturn: values?.maxGatePassesInPendingReturn ?? 0,
					maxGatePassesInPendingCheckout: values?.maxGatePassesInPendingCheckout ?? 0,
					gatePassCreationExpiresAt: currentUser.gatePassCreationExpiresAt,
				}
			})
			.then(() => {
				console.info("User config updated successfully");
				Toast.success("User config updated successfully");
				this.formRef.current?.resetFields();
				this.setState({ inProgress: false });
				onClose();
			})
			.catch((error: any) => {
				console.error("User config update failed", error ?? "");
				Toast.error("User config update failed");
				this.setState({ inProgress: false });
			});
	};

	private getInitialValues() {
		const { currentUser } = this.props;
		return {
			maxGatePassesInPendingReturn: currentUser?.maxGatePassesInPendingReturn ?? null,
			maxGatePassesInPendingCheckout: currentUser?.maxGatePassesInPendingCheckout ?? null
		};
	}

	render() {
		const { onClose } = this.props;
		const { inProgress } = this.state;
		return <div>
			<Form ref={ this.formRef } layout="vertical" onFinish={ this.onFinish }
			      initialValues={ this.getInitialValues() }>
				<Form.Item name="maxGatePassesInPendingReturn" label="Max No of Pending Return Gate Passes" required>
					<InputNumber min={ 1 }/>
				</Form.Item>

				<Form.Item name="maxGatePassesInPendingCheckout" label="Max No of Pending Checkout Gate Passes">
					<InputNumber min={ 1 }/>
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit" style={ { marginTop: 16 } } loading={ inProgress }
					        disabled={ inProgress }>
						Submit
					</Button>
					<Button type="default" style={ { marginLeft: 16 } } onClick={ onClose } disabled={ inProgress }>
						Cancel
					</Button>
				</Form.Item>
			</Form>
		</div>;
	}
}