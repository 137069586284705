import { UserConfig, UserManagerService } from "../services/admin/UserManagerService";
import { GatePassManagerService, GatePassSchema, GatePassStatus } from "../services/common/GatePassManagerService";
import { getDifferenceInDays, getDifferenceInHours } from "./utils";

export const checkCreationOperationRules = (userId: string): boolean => {
    // No of overdue days to be checked when evaluating create operation rules
    // TODO: make this value a system parameter which can be configured by admin
    const OVERDUE_DAYS_COUNT_TO_CHECK: number = 7;

    const gatePasses: GatePassSchema[] = GatePassManagerService.getInstance().getAllActiveGatePasses();
    const myActiveGatePasses: GatePassSchema[] = gatePasses.filter(gatePass => (
        gatePass.createdBy === userId &&
        [GatePassStatus.PENDING_CHECKOUT, GatePassStatus.PENDING_RECEPTION, GatePassStatus.PARTIALLY_RECEIVED].includes(gatePass.status)
    ));

    const now: number = Date.now();
    let expiredPendingCheckoutGatePassesCount: number = 0;
    let expiredPendingReceptionGatePassesCount: number = 0;
    let isGatePassCreationAllowed: boolean = true;

    for (const gatePass of myActiveGatePasses) {
        let dfpc: number = 0;
        let dfpr: number = 0;

        if (gatePass.status === GatePassStatus.PENDING_CHECKOUT) {
            dfpc = getDifferenceInDays(now, gatePass.checkoutDate * 1000);
        } else if ([GatePassStatus.PENDING_RECEPTION, GatePassStatus.PARTIALLY_RECEIVED].includes(gatePass.status) && gatePass.returnDate) {
            dfpr = getDifferenceInDays(now, gatePass.returnDate * 1000);
        }

        if (dfpc > OVERDUE_DAYS_COUNT_TO_CHECK) {
            expiredPendingCheckoutGatePassesCount++;
        }

        if (dfpr > OVERDUE_DAYS_COUNT_TO_CHECK) {
            expiredPendingReceptionGatePassesCount++;
        }
    }

    const userConfig: UserConfig = UserManagerService.getInstance().getUserConfig(userId);

    if ((userConfig?.maxGatePassesInPendingCheckout && expiredPendingCheckoutGatePassesCount >= userConfig?.maxGatePassesInPendingCheckout) ||
        (userConfig?.maxGatePassesInPendingReturn && expiredPendingReceptionGatePassesCount >= userConfig?.maxGatePassesInPendingReturn)) {
        isGatePassCreationAllowed = false;
    }

    if (!isGatePassCreationAllowed && userConfig.gatePassCreationExpiresAt !== null && userConfig.gatePassCreationExpiresAt > now) {
        isGatePassCreationAllowed = true;
    }


    return isGatePassCreationAllowed;
}

export const hasGPCreationBanLiftedFor24Hrs = (gatePassCreationExpiresAt: number | null) => {
    if (!gatePassCreationExpiresAt) return false;
    const differenceInHours = getDifferenceInHours(gatePassCreationExpiresAt, Date.now());
    return differenceInHours > 0 && differenceInHours < 24;
};