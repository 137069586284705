import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Col, DatePicker, Divider, Form, FormInstance, Input, InputNumber, Popconfirm, Row, Select, Table } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs, { Dayjs } from "dayjs";
import React from "react";
import { UserRole } from "../../../defs/common";
import { ItemManagerService } from "../../../services/admin/ItemManagerService";
import { UserManagerService } from "../../../services/admin/UserManagerService";
import { AuthenticationService, AuthUser } from "../../../services/common/AuthenticationService";
import { EditableGatePassItem, EditableGatePassWithItems, GatePassItem, GatePassManagerService } from "../../../services/common/GatePassManagerService";
import { getUnitOfMeasurementDisplayValue, getUnitOfMeasurements } from "../../../utils/utils";
import { GenericModal } from "../../GenericModal/GenericModal";
import { Toast } from "../../Toast/Toast";
import "./EditGatePassForm.scss";

import { convertToDayJS } from "../../../utils/form-utils";

// const disablePastDates = (current: Dayjs | null): boolean => {
//     return current ? current.isBefore(dayjs().startOf('day')) : false;
// };

interface EditGatePassFormProps {
    gatePassNo: string;
    currentData: any;
    onClose: () => void;
}

interface GatePassItemRow {
    quantity: number;
    serialNumber: string;
    itemCode: string;
    itemName: string;
    measurementUnit: string;
    isEditable: boolean;
    isNewlyAdded: boolean;
    isDeleted: boolean;
}

interface EditGatePassFormState {
    allUsers: any[];
    allItems: any[];
    editedGatePassItems: GatePassItemRow[];
    gatePassItemRows: GatePassItemRow[];
    currentUser: AuthUser | null;
    itemsAutoCompleteOptions: { value: string; }[];
    isItemModalVisible: boolean;
    isGatePassEditInProgress: boolean;
    isItemCreateInProgress: boolean;
    isAutoCompleteItemSelected: boolean;
    isGatePassItemsLoading: boolean;
    isSubmissionInProgress: boolean;
    checkoutDate: Dayjs | null;
}

interface GatePassEditFormData {
    type: string;
    checkOutDate: dayjs.Dayjs;
    returnDate?: dayjs.Dayjs;
    vehicleNo?: string;
    location?: string;
    reason?: string;
    gatePassHolder?: string;
    gatePassApprover?: string;
}

export class EditGatePassForm extends React.Component<EditGatePassFormProps, EditGatePassFormState> {
    private mainFormRef: React.RefObject<FormInstance>;
    private itemsFormRef: React.RefObject<FormInstance>;
    private prevGatePassItems: GatePassItemRow[];

    constructor(props: EditGatePassFormProps) {
        super(props);

        this.prevGatePassItems = [];

        this.state = {
            allUsers: [],
            allItems: [],
            gatePassItemRows: [],
            editedGatePassItems: [],
            currentUser: null,
            itemsAutoCompleteOptions: [],
            isItemModalVisible: false,
            isGatePassEditInProgress: false,
            isItemCreateInProgress: false,
            isAutoCompleteItemSelected: false,
            isGatePassItemsLoading: false,
            isSubmissionInProgress: false,
            checkoutDate: null
        };

        this.mainFormRef = React.createRef();
        this.itemsFormRef = React.createRef();
    }

    componentDidMount(): void {
        this.loadInitialData();
        this.getGatePassItemData(this.props.gatePassNo);
        // this.setInitialFormData(this.props.currentData);
    }

    componentDidUpdate(prevProps: Readonly<EditGatePassFormProps>): void {
        if (this.props.gatePassNo !== prevProps.gatePassNo) {
            this.getGatePassItemData(this.props.gatePassNo);
        }

        // if (this.props.currentData !== prevProps.currentData) {
        //     this.setInitialFormData(this.props.currentData);
        // }
    }

    private loadInitialData = async () => {
        try {
            const allItems = ItemManagerService.getInstance().getAllItems();
            const itemsAutoCompleteOptions = allItems.map((item) => ({ value: item.itemName }));

            this.setState({
                allUsers: UserManagerService.getInstance().getAllUsers(), // need a filter
                currentUser: await AuthenticationService.getInstance().getCurrentUser(),
                allItems,
                itemsAutoCompleteOptions,
            });
        } catch (error) {
            console.error("Error loading initial data");
        }
    };

    // private setInitialFormData = (data: any) => {
    //     if (data) {
    //         const fullData = {
    //             // ...data,
    //             type: getGatePassTypeDisplayValue(data.type),
    //             approverName: this.getUserName(data.approverUserId),
    //             holderName: this.getUserName(data.holderUserId),
    //             // checkOutDate: formatDate(data.checkoutDate),
    //             // returnDate: formatDate(data.returnDate)
    //         };

    //         this.mainFormRef.current?.setFieldsValue(fullData);

    //         this.setState({ isAutoCompleteItemSelected: true, checkoutDate: convertToDayJS(data.checkOutDate) });
    //     }
    // };

    private getGatePassItemData = (gatePassNo: string) => {
        this.setState({ gatePassItemRows: [], isGatePassItemsLoading: true });
        GatePassManagerService.getInstance()
            .getItemsOfTheGatePass(gatePassNo)
            .then((data: GatePassItem[]) => {
                const editedGatePassItems: GatePassItemRow[] = data.map((item: GatePassItem) => ({
                    itemCode: item.itemCode,
                    quantity: item.totalQuantity,
                    itemName: item.itemName,
                    measurementUnit: item.measurementUnit,
                    serialNumber: item.serialNumber,
                    isDeleted: false,
                    isEditable: false,
                    isNewlyAdded: false,
                }));

                this.prevGatePassItems = structuredClone(editedGatePassItems);
                this.setState({ gatePassItemRows: [...editedGatePassItems], isGatePassItemsLoading: false });
            })
            .catch((error: any) => {
                console.error("Error retrieving gate pass items", error ?? undefined);
                this.setState({ gatePassItemRows: [], isGatePassItemsLoading: false });
            });
    };

    // private onEditGatePassFormClose = () => {
    //     this.mainFormRef.current?.resetFields();
    //     // this.setState({ showNewGatePassDialog: false });
    // };

    private onNewItemFormClose = () => {
        this.mainFormRef.current?.resetFields();
        this.setState({ isItemModalVisible: false });
    };

    private submitEditedGatePassForm = async (formData: GatePassEditFormData): Promise<void> => {
        const { gatePassItemRows, isSubmissionInProgress } = this.state;
        const { gatePassNo, onClose } = this.props;

        if (isSubmissionInProgress) return;

        if (gatePassItemRows.length === 0) {
            Toast.error("Edit Gate Pass", "Please add one more item(s) to the gate pass.");
            return;
        }

        this.setState({ isGatePassEditInProgress: true });

        try {

            const gatePassItems: EditableGatePassItem[] = gatePassItemRows.map(itemRow => ({
                itemCode: itemRow.itemCode,
                isDeleted: itemRow.isDeleted,
                isNewlyAdded: itemRow.isNewlyAdded,
                quantity: itemRow.quantity
            }));

            const editableGatePass: EditableGatePassWithItems = {
                // checkoutDate: formData.checkOutDate?.unix(),
                // returnDate: formData.returnDate?.unix() ?? null,
                location: formData.location ?? "",
                reason: formData.reason ?? "",
                vehicleNo: formData.vehicleNo ?? null,
                approverUserId: formData.gatePassApprover ?? "",
                items: gatePassItems,
            };

            await GatePassManagerService.getInstance().amendGatePass(gatePassNo, editableGatePass);
            Toast.success("Gate pass updated successfully");

            onClose();


        } catch (error: any) {
            console.error("Gate pass update failed" ?? "");
            Toast.error("Gate pass update failed", error.message);
        } finally {
            this.mainFormRef.current?.resetFields();
            this.setState({ isGatePassEditInProgress: false });
        }
    };

    private openNewItemModal = () => {
        this.setState({
            isItemModalVisible: true,
        });
    };

    private handleAddNewItem = (values: any) => {
        const { gatePassItemRows, allItems, isAutoCompleteItemSelected } = this.state;

        if (isAutoCompleteItemSelected) {
            const selectedItem = allItems.find((item) => item.itemName === values.description);
            const selectedGatePassItemRow: GatePassItemRow = {
                itemCode: selectedItem?.itemCode,
                itemName: selectedItem?.itemName,
                serialNumber: selectedItem?.serialNumber,
                measurementUnit: selectedItem?.measurementUnit,
                quantity: values?.qty,
                isDeleted: false,
                isEditable: false,
                isNewlyAdded: true
            };

            this.itemsFormRef.current?.resetFields();

            this.setState({
                gatePassItemRows: [...gatePassItemRows, selectedGatePassItemRow],
                isItemModalVisible: false,
            });
        } else {
            if (!values || !values?.description || !values?.serialNo || !values?.uom || !values?.qty) {
                Toast.error("Item creation failed", "Missing form fields");
                return;
            }

            this.setState({ isItemCreateInProgress: true });

            ItemManagerService.getInstance()
                .createItem({
                    itemName: values?.description,
                    serialNumber: values?.serialNo,
                    measurementUnit: values?.uom,
                })
                .then((itemCode: string) => {
                    Toast.success("Item created successfully");

                    const newlyAddedGatePassItemRow: GatePassItemRow = {
                        itemCode,
                        itemName: values?.description,
                        serialNumber: values?.serialNo,
                        measurementUnit: values?.uom,
                        quantity: values?.qty,
                        isDeleted: false,
                        isEditable: false,
                        isNewlyAdded: true
                    };

                    this.itemsFormRef.current?.resetFields();

                    const updatedGatePassItemRows: GatePassItemRow[] = [...gatePassItemRows, newlyAddedGatePassItemRow];

                    this.setState({
                        gatePassItemRows: updatedGatePassItemRows,
                        isItemModalVisible: false,
                        isAutoCompleteItemSelected: false,
                        isItemCreateInProgress: false,
                    });

                    return;
                })
                .catch((error: any) => {
                    Toast.error("Item creation failed");
                    this.setState({ isItemCreateInProgress: false });
                });
        }

    };

    private editGatePassItem = (gatePassItemRow: GatePassItemRow) => {
        const { gatePassItemRows } = this.state;
        const updatedGatePassItemRows: GatePassItemRow[] = [...gatePassItemRows];

        const indexOfTheItemRow: number = updatedGatePassItemRows.findIndex((item) => item.itemCode === gatePassItemRow.itemCode);

        if (indexOfTheItemRow === -1) {
            // TODO: Show Toast: Item Not Found
            return;
        }

        updatedGatePassItemRows[indexOfTheItemRow].isEditable = true;

        this.setState({ gatePassItemRows: updatedGatePassItemRows });
    };

    private handleItemDescriptionSelect = (value: string) => {
        const { allItems } = this.state;
        const selectedItem = allItems.find((item) => item.itemName === value);
        if (selectedItem) {
            this.itemsFormRef.current?.setFieldsValue({
                itemCode: selectedItem.itemCode,
                serialNo: selectedItem.serialNumber,
                uom: selectedItem.measurementUnit,
            });

            this.setState({ isAutoCompleteItemSelected: true });
        }
    };

    private handleItemDescriptionChange = (value: string) => {
        const { gatePassItemRows } = this.state;
        if (!gatePassItemRows.find((item) => item.itemName === value)) {
            this.setState({ isAutoCompleteItemSelected: false });
            this.itemsFormRef.current?.resetFields(["serialNo", "uom", "qty"]);
        }
    };

    private renderUOMOptions = () => {
        return getUnitOfMeasurements().sort((a, b) => a.label.localeCompare(b.label)).map((item) => {
            return <Select.Option value={item.value}>{item.label}</Select.Option>;
        });
    };

    private renderAddItemForm = (): React.ReactNode => {
        const { isAutoCompleteItemSelected, isItemCreateInProgress, itemsAutoCompleteOptions } = this.state;

        return (
            <Form className="items-edit-form" ref={this.itemsFormRef} layout="vertical" onFinish={this.handleAddNewItem}>
                <Form.Item name="description" label="Description" required>
                    <AutoComplete
                        options={itemsAutoCompleteOptions}
                        onSelect={this.handleItemDescriptionSelect}
                        onChange={this.handleItemDescriptionChange}
                        filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                    />
                </Form.Item>

                <Form.Item name="serialNo" label="Serial No">
                    <Input disabled={isAutoCompleteItemSelected} />
                </Form.Item>

                <Form.Item name="uom" label="Unit of Measurement" rules={[{ required: true, message: "Unit of measurement is required" }]}>
                    <Select disabled={isAutoCompleteItemSelected}>
                        {this.renderUOMOptions()}
                    </Select>
                </Form.Item>

                <Form.Item name="qty" label="Quantity" rules={[{ required: true, message: "Quantity is required" }]}>
                    <InputNumber min={1} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ marginTop: 16 }} loading={isItemCreateInProgress}>
                        Submit
                    </Button>
                    <Button type="default" style={{ marginLeft: 16 }} onClick={this.onNewItemFormClose}>
                        Cancel
                    </Button>
                </Form.Item>
            </Form>
        );
    };

    private handleQuantityValueChange = (itemCode: string, inputValue: number): void => {
        const { gatePassItemRows } = this.state;
        const updatedGatePassItemRows: GatePassItemRow[] = [...gatePassItemRows];

        const indexOfTheItemRow: number = updatedGatePassItemRows.findIndex((item) => item.itemCode === itemCode);

        if (indexOfTheItemRow === -1) {
            // TODO: Show Toast: Item Not Found
            return;
        }

        let quantity: number = 0;

        if (inputValue === null || inputValue < 0) quantity = 0;
        else quantity = Number(inputValue);

        updatedGatePassItemRows[indexOfTheItemRow].quantity = quantity;

        this.setState({ gatePassItemRows: updatedGatePassItemRows });
    };

    private saveItemChanges = (gatePassItemRow: GatePassItemRow) => {
        const { gatePassItemRows } = this.state;
        const updatedGatePassItemRows: GatePassItemRow[] = [...gatePassItemRows];

        const indexOfTheItemRowToBeEdited: number = updatedGatePassItemRows.findIndex((item) => item.itemCode === gatePassItemRow.itemCode);
        const indexOfTheItemRowInPrevState: number = this.prevGatePassItems.findIndex((item) => item.itemCode === gatePassItemRow.itemCode);


        if (indexOfTheItemRowToBeEdited === -1 || indexOfTheItemRowInPrevState === -1) {
            console.error("Save Changes:", { indexOfTheItemRowInPrevState, indexOfTheItemRowToBeEdited });

            // TODO: Show Toast: Item Not Found
            return;
        }

        updatedGatePassItemRows[indexOfTheItemRowToBeEdited].isEditable = false;
        this.prevGatePassItems[indexOfTheItemRowInPrevState].quantity = gatePassItemRow.quantity;

        this.setState({ gatePassItemRows: updatedGatePassItemRows });
    };

    private revertChanges = (gatePassItemRow: GatePassItemRow) => {
        const { gatePassItemRows } = this.state;
        const updatedGatePassItemRows: GatePassItemRow[] = [...gatePassItemRows];

        const indexOfTheItemRowToBeEdited: number = updatedGatePassItemRows.findIndex((item) => item.itemCode === gatePassItemRow.itemCode);
        const indexOfTheItemRowInPrevState: number = this.prevGatePassItems.findIndex((item) => item.itemCode === gatePassItemRow.itemCode);

        if (indexOfTheItemRowToBeEdited === -1 || indexOfTheItemRowInPrevState === -1) {
            // TODO: Show Toast: Item Not Found
            return;
        }

        updatedGatePassItemRows[indexOfTheItemRowToBeEdited].quantity = this.prevGatePassItems[indexOfTheItemRowInPrevState].quantity;
        updatedGatePassItemRows[indexOfTheItemRowToBeEdited].isEditable = false;

        this.setState({ gatePassItemRows: updatedGatePassItemRows });
    };

    private deleteItemFromTheTable = (gatePassItemRow: any): void => {
        if (!gatePassItemRow) return;


        const { gatePassItemRows } = this.state;
        const updatedGatePassItemRows: GatePassItemRow[] = [...gatePassItemRows];

        const indexOfTheItemRowToBeEdited: number = updatedGatePassItemRows.findIndex((item) => item.itemCode === gatePassItemRow.itemCode);

        if (indexOfTheItemRowToBeEdited === -1) {
            // TODO: Show Toast: Item Not Found
            return;
        }

        updatedGatePassItemRows[indexOfTheItemRowToBeEdited].isDeleted = true;
        this.setState({ gatePassItemRows: updatedGatePassItemRows });

    };

    private actionsCellRenderer = (_: any, record: any): JSX.Element => {
        return (
            <div className="item-action-container">
                {record.isEditable ? (
                    <React.Fragment>
                        <Button
                            className="btn-save"
                            type="primary"
                            shape="circle"
                            size="small"
                            icon={<CheckOutlined />}
                            onClick={() => this.saveItemChanges(record)}
                        />
                        <Button
                            className="btn-cancel"
                            type="primary"
                            shape="circle"
                            size="small"
                            icon={<CloseOutlined />}
                            onClick={() => this.revertChanges(record)}
                        />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Button
                            className="btn-edit"
                            type="primary"
                            shape="circle"
                            size="small"
                            icon={<EditOutlined />}
                            onClick={() => this.editGatePassItem(record)}
                        />

                        <Popconfirm
                            title="Delete the Item"
                            description="Are you sure to delete this item?"
                            onConfirm={() => this.deleteItemFromTheTable(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                className="btn-delete"
                                type="primary"
                                shape="circle"
                                size="small"
                                icon={<DeleteOutlined />}
                            />
                        </Popconfirm>

                    </React.Fragment>
                )}
            </div>
        );
    };

    private quantityCellRenderer = (_: any, record: any): JSX.Element => {
        let isEditable: boolean = record.isEditable ?? false;
        return isEditable ? <InputNumber value={record.quantity} onChange={(value: any) => this.handleQuantityValueChange(record.itemCode, value)} /> : <div>{record.quantity}</div>;
    };

    private getItemsTableColumnConfig = (): any[] => {
        return [
            { title: "Item Code", dataIndex: "itemCode", key: "itemCode" },
            { title: "Description", dataIndex: "itemName", key: "itemName" },
            { title: "Serial No", dataIndex: "serialNumber", key: "serialNumber" },
            {
                title: "Unit of Measurement",
                dataIndex: "measurementUnit",
                key: "measurementUnit",
                render: (value: any) => { return getUnitOfMeasurementDisplayValue(value); }
            },
            { title: "Quantity", dataIndex: "totalQuantity", key: "totalQuantity", render: this.quantityCellRenderer },
            { title: "Action", key: "action", render: this.actionsCellRenderer },
        ];
    };

    private getInitialValuesOfTheForm = (): GatePassEditFormData => {
        const { currentData } = this.props;
        return ({
            checkOutDate: convertToDayJS(currentData.checkOutDate),
            type: currentData.type,
            gatePassApprover: currentData.approverUserId,
            gatePassHolder: currentData.holderUserId,
            location: currentData.location,
            reason: currentData.reason,
            returnDate: currentData.returnDate !== "" ? convertToDayJS(currentData.returnDate) : undefined,
            vehicleNo: currentData.vehicleNo
        });
    };

    // private onCheckOutDateChange = (date: Dayjs | null) => {
    //     this.setState({ checkoutDate: date });
    //     this.mainFormRef.current?.resetFields(["returnDate"]);
    // };

    private disabledReturnDate = (current: Dayjs | null) => {
        const { checkoutDate } = this.state;
        return current && checkoutDate ? current.isBefore(checkoutDate, 'day') : false;
    };

    render(): React.ReactNode {
        const { allUsers, isGatePassEditInProgress, isGatePassItemsLoading, gatePassItemRows, isItemModalVisible } = this.state;
        // const { currentData } = this.props;
        const itemsTableColumns = this.getItemsTableColumnConfig();
        const filteredGatePassItemRows: GatePassItemRow[] = gatePassItemRows.filter(item => !item.isDeleted);
        // const isNonReturnableGatePass: boolean = currentData.type === GatePassType.NON_RETURNABLE;
        const isGatePassItemEditInProgress: boolean = gatePassItemRows.filter(item => item.isEditable).length > 0;

        const holderUsers = allUsers
            .filter((user: any) => [UserRole.USER_LEVEL_1, UserRole.GUEST_USER].includes(user.role))
            .map((user: any) => ({ label: `${user.firstName} ${user.lastName}`, value: user.userId }));
        const approverUsers = allUsers.filter((user: any) => user.role === UserRole.USER_LEVEL_0).map((user: any) => ({ label: `${user.firstName} ${user.lastName}`, value: user.userId }));

        return (
            <Form
                onFinish={this.submitEditedGatePassForm}
                ref={this.mainFormRef}
                layout="vertical"
                className="edit-gate-pass-form"
                initialValues={this.getInitialValuesOfTheForm()}
            >
                <Row gutter={16}>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item name="type" label="Gate Pass Type" rules={[{ required: true, message: "Gate pass type is required" }]}>
                            <Select className="gate-pass-type-selector" disabled>
                                <Select.Option value="RETURNABLE">Returnable</Select.Option>
                                <Select.Option value="NON_RETURNABLE">Non Returnable</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item name="checkOutDate" label="Check Out Date"
                        // rules={[{ required: true, message: "Check out date is required" }]}
                        >
                            {/* <DatePicker disabledDate={disablePastDates} onChange={this.onCheckOutDateChange} /> */}
                            <DatePicker disabled className="edit-gp-form-date-picker" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item name="returnDate" label="Return Date"
                        // dependencies={["type"]} 
                        // rules={[{ required: true, message: "Return date is required" }]}
                        >
                            {/* <DatePicker disabled={isNonReturnableGatePass} disabledDate={this.disabledReturnDate} /> */}
                            <DatePicker disabled className="edit-gp-form-date-picker" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                        <Form.Item name="vehicleNo" label="Vehicle No">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="location" label="Location" rules={[{ required: true, message: "Location is required" }]}>
                    <Input />
                </Form.Item>

                <Form.Item name="reason" label="Reason" rules={[{ required: true, message: "Reason is required" }]}>
                    <TextArea rows={2} maxLength={200} showCount />
                </Form.Item>

                <Form.Item name="gatePassHolder" label="Gate Pass Holder" rules={[{ required: true, message: "Gate pass holder is required" }]}>
                    <Select showSearch optionFilterProp="label" options={holderUsers} />
                </Form.Item>

                <Form.Item name="gatePassApprover" label="Gate Pass Approver" rules={[{ required: true, message: "Gate pass approver is required" }]}>
                    <Select showSearch optionFilterProp="label" options={approverUsers} />
                </Form.Item>



                <Divider></Divider>

                <Button type="dashed" onClick={this.openNewItemModal} icon={<PlusOutlined />}>
                    Add Item
                </Button>

                <Table className="items-table"
                    dataSource={filteredGatePassItemRows}
                    columns={itemsTableColumns}
                    rowKey="itemCode"
                    pagination={false}
                    loading={isGatePassItemsLoading}
                    scroll={{ x: 'max-content' }}
                    bordered
                />

                <Form.Item className="form-actions">
                    {/* <Button className="cancel-btn" type="default" onClick={this.onEditGatePassFormClose}>
                        Cancel
                    </Button> */}
                    <Button icon={<CheckOutlined />} type="primary" htmlType="submit" className="update-details-btn" loading={isGatePassEditInProgress} disabled={isGatePassEditInProgress || isGatePassItemEditInProgress}>
                        Save Changes
                    </Button>
                </Form.Item>

                <GenericModal title="Add New Item" open={isItemModalVisible} onCancel={this.onNewItemFormClose} width={500}>
                    {this.renderAddItemForm()}
                </GenericModal>
            </Form>
        );
    }
}
